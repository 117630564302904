import React from 'react'

import { Card } from '@/components/ui/card'
import type { Enterprise } from '@/store/api/Enterprises/Enterprises.types'

import { CreditManagementSheet } from './CreditManagementSheet'
import { PdfPreferencesSheet } from './PdfPreferencesSheet'

interface CompanyUsersHeaderTypes {
  companyName: string
  logo: string
  enterpriseData: Enterprise
}

export const CompanyUsersHeader = ({
  companyName,
  logo,
  enterpriseData,
}: CompanyUsersHeaderTypes) => {
  return (
    <Card className="flex items-center justify-between h-16 p-4 rounded-xl ">
      <div className="flex gap-x-2.5">
        <div className="flex items-center justify-center">
          <div className="flex w-[32px] h-[32px]">
            <img
              src={logo}
              alt={companyName}
              className="object-contain w-full h-full"
            />
          </div>
        </div>
        <p className="text-2xl font-medium ">{companyName}</p>
      </div>
      <div className="flex gap-3">
        <PdfPreferencesSheet
          currentLogoUrl={enterpriseData.logo}
          currentWhiteLabelingName={enterpriseData.displayName}
          currentBackgroundColor={enterpriseData.palletBgColor}
          currentHighlightColor={enterpriseData.palletHlColor}
          currentAccentColor={enterpriseData.palletAcColor}
          currentTextColor={enterpriseData.palletTextColor}
          currentText60Color={enterpriseData.palletTextColor60}
        />
        <CreditManagementSheet currentCredits={enterpriseData.credits} />
      </div>
    </Card>
  )
}
