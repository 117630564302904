import React from 'react'

import {
  Sheet,
  SheetTrigger,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetDescription,
} from '@/components/ui/sheet'
import type { ReportItem } from '@/store/api/Admin/Admin.types'

import { SubjectInputsCard } from './SubjectInputsCard'

export const SubjectInputsSheet = ({ data }: { data: ReportItem }) => {
  const subjectInputs = [
    { title: 'Name', text: data.inputs.name || '-' },
    { title: 'Nickname', text: data.inputs.nickname || '-' },
    { title: 'Email', text: data.inputs.email || '-' },
    { title: 'Phone', text: data.inputs.phone || '-' },
    {
      title: 'Age or Date of Birth',
      text: data.inputs.ageOrDateOfBirth || '-',
    },
    { title: 'State', text: data.inputs.state || '-' },
    { title: 'Country', text: data.inputs.country || '-' },
    { title: 'City', text: data.inputs.city || '-' },
    { title: 'College', text: data.inputs.college || '-' },
    { title: 'Company', text: data.inputs.company || '-' },
    { title: 'High School', text: data.inputs.highSchool || '-' },
    { title: 'Facebook', text: data.inputs.facebookUrl || '-' },
    { title: 'Instagram', text: data.inputs.instagramUrl || '-' },
    { title: 'Twitter', text: data.inputs.twitterUrl || '-' },
    { title: 'Linkedin', text: data.inputs.linkedInUrl || '-' },
    { title: 'Tik Tok', text: data.inputs.tikTokUrl || '-' },
    { title: 'Pinteres', text: data.inputs.pinterestUrl || '-' },
    {
      title: 'Language',
      text: data.pdfLanguage === 'en' ? 'English' : 'Spanish',
    },
    {
      title: 'Topics',
      text:
        data.inputs.topics.length > 0 ? data.inputs.topics.join(' - ') : '-',
    },
    { title: 'Website', text: data.inputs.website || '-' },
    { title: 'Industry', text: data.inputs.industry || '-' },
    { title: 'Use Case', text: data.useCase || '-' },
  ]
  return (
    <Sheet>
      <SheetTrigger className="w-full">
        <p className="relative flex w-full cursor-default select-none items-center rounded-sm py-1.5 pl-2 pr-8 outline-none focus:bg-primaryColor5 focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50 h-[35px] p-2 text-neutralColor60 text-sm font-medium tracking-tight hover:bg-primaryColor5">
          Subject Inputs
        </p>
      </SheetTrigger>
      <SheetContent className="flex flex-col gap-5 overflow-scroll">
        <SheetHeader className="flex flex-col gap-3">
          <SheetTitle className="text-xl font-medium text-neutralColor">
            Subject Inputs
          </SheetTitle>
          <SheetDescription className="text-base font-normal text-neutralColor60">
            The following details were utilized to run the Social Media
            Analysis.
          </SheetDescription>
        </SheetHeader>
        <div className="flex flex-col gap-5 w-full">
          {subjectInputs.map((preference, index) => (
            <SubjectInputsCard
              key={index}
              title={preference.title}
              text={preference.text}
            />
          ))}
        </div>
      </SheetContent>
    </Sheet>
  )
}
