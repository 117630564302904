import { ESocialMedias, SocialMediaPatterns } from '../constants/socialMedias'

import {
  FACEBOOK_HASHTAG_PREFIX,
  FACEBOOK_PHOTO_ID_PREFIX,
  FACEBOOK_PHOTO_PREFIX,
  FACEBOOK_POST_PREFIX,
  INSTAGRAM_POST_PREFIX,
  LINKEDIN_COMPANY_PREFIX,
  LINKEDIN_POST_PREFIX,
  LINKEDIN_PUBLICATION_PREFIX,
  PINTEREST_PIN_PREFIX,
  REDDIT_REDDIT_PREFIX,
  TIKTOK_MUSIC_PREFIX,
  TIKTOK_VIDEO_PREFIX,
  X_URL_STATUS_PREFIX,
} from './sanitizeSocialMediaUrl'

export const identifySocialMedia = (url: string): ESocialMedias | undefined => {
  for (const [platform, pattern] of Object.entries(SocialMediaPatterns)) {
    if (pattern.test(url))
      return ESocialMedias[platform as keyof typeof ESocialMedias]
  }
}

export const identifySocialMediaProfile = (url: string): boolean => {
  for (const [platform, pattern] of Object.entries(SocialMediaPatterns)) {
    if (pattern.test(url)) {
      switch (platform) {
        case ESocialMedias.LinkedIn:
          if (
            url.includes(LINKEDIN_POST_PREFIX) ||
            url.includes(LINKEDIN_PUBLICATION_PREFIX) ||
            url.includes(LINKEDIN_COMPANY_PREFIX)
          ) {
            return false
          }

          return true
        case ESocialMedias.Twitter:
          if (url.includes(X_URL_STATUS_PREFIX)) {
            return false
          }

          return true
        case ESocialMedias.Instagram:
          if (url.includes(INSTAGRAM_POST_PREFIX)) {
            return false
          }

          return true
        case ESocialMedias.Reddit:
          if (url.includes(REDDIT_REDDIT_PREFIX)) {
            return false
          }

          return true
        case ESocialMedias.Pinterest:
          if (url.includes(PINTEREST_PIN_PREFIX)) {
            return false
          }

          return true
        case ESocialMedias.Facebook:
          if (
            url.includes(FACEBOOK_PHOTO_PREFIX) ||
            url.includes(FACEBOOK_PHOTO_ID_PREFIX) ||
            url.includes(FACEBOOK_POST_PREFIX) ||
            url.includes(FACEBOOK_HASHTAG_PREFIX)
          ) {
            return false
          }

          return true
        case ESocialMedias.TikTok:
          if (
            url.includes(TIKTOK_VIDEO_PREFIX) ||
            url.includes(TIKTOK_MUSIC_PREFIX)
          ) {
            return false
          }

          return true
        default:
          return false
      }
    }
  }

  return false
}

export const identifySocialMediaPost = (url: string): boolean => {
  // We not include  Linkedin Company Profile and Reddit /r/, because we support it.
  return (
    // LinkedIn
    url.includes(LINKEDIN_POST_PREFIX) ||
    url.includes(LINKEDIN_PUBLICATION_PREFIX) ||
    // X
    url.includes(X_URL_STATUS_PREFIX) ||
    // Instagram
    url.includes(INSTAGRAM_POST_PREFIX) ||
    // Pinterest
    url.includes(PINTEREST_PIN_PREFIX) ||
    // TikTok
    url.includes(TIKTOK_VIDEO_PREFIX) ||
    url.includes(TIKTOK_MUSIC_PREFIX) ||
    // Facebook
    url.includes(FACEBOOK_PHOTO_PREFIX) ||
    url.includes(FACEBOOK_PHOTO_ID_PREFIX) ||
    url.includes(FACEBOOK_POST_PREFIX) ||
    url.includes(FACEBOOK_HASHTAG_PREFIX)
  )
}
