import React, { useState } from 'react'
import { toast } from 'sonner'

import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { SocialMediaPatterns } from '@/constants/socialMedias'
import { useUploadProfilesMutation } from '@/store/api/Scrape/Scrape.query'
import type { ESocialMedias } from '@/store/api/Scrape/Scrape.types'
import { EProfileFindingsStatus } from '@/store/api/Scrape/Scrape.types'
import type { PersonData } from '@/store/api/Subjects/Subjects.types'
import { sanitizeSocialMediaUrl } from '@/utils/sanitizeSocialMediaUrl'

const ProfileUploader = ({
  subjectId,
}: {
  subjectId: string
  personData?: PersonData
}) => {
  const [profileUrl, setProfileUrl] = useState('')

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setProfileUrl(event.target.value)
  }

  const [uploadProfiles, { isLoading }] = useUploadProfilesMutation()

  const handleSubmit = async () => {
    const sanitizedUrl = sanitizeSocialMediaUrl(profileUrl)

    let detectedPlatform: ESocialMedias | null = null
    for (const [platform, pattern] of Object.entries(SocialMediaPatterns)) {
      if (pattern.test(sanitizedUrl)) {
        detectedPlatform = platform as ESocialMedias
        break
      }
    }

    if (!detectedPlatform) {
      toast.error('No se pudo detectar la red social de la URL proporcionada')
      return
    }

    setProfileUrl(sanitizedUrl)

    try {
      const result = await uploadProfiles({
        subjectId: subjectId,
        profiles: {
          url: sanitizedUrl,
          platform: detectedPlatform as any,
          status: EProfileFindingsStatus.CHECKED,
        },
      })

      if ('error' in result) {
        toast.error('Error al subir el perfil')
      } else {
        toast.success('Perfil subido exitosamente')
      }
      setProfileUrl('')
    } catch (error) {
      console.error('Error en la mutación:', error)
      setProfileUrl('')
    }
  }

  return (
    <div className="flex flex-col gap-4">
      <h2>Upload Profiles</h2>

      <div className="flex w-full gap-6">
        <Input
          type="text"
          name="url"
          placeholder="URL"
          className="w-96"
          value={profileUrl}
          onChange={handleInputChange}
        />
      </div>

      <Button onClick={handleSubmit} isLoading={isLoading}>
        Add Profile
      </Button>
    </div>
  )
}

export default ProfileUploader
