import { Edit2 } from 'iconsax-react'

import { Button } from '@/components/ui/button'
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import type { Summary } from '@/store/api/Subjects/Subjects.types'
import { weCheckPalette } from '@/utils/palette/weCheckPalette'

import { SummaryCard } from './SummaryCard'

interface DisplayCoreFindingsTypes {
  summary: Summary
}

export const DisplayCoreFindings = ({ summary }: DisplayCoreFindingsTypes) => {
  const summaryFields = [
    { title: 'Aim', text: summary.executiveAim },
    { title: 'Executive Summary', text: summary.executiveSummary },
    { title: 'Risk Indicators', text: summary.riskIndicators },
    { title: 'Sentiment Analysis', text: summary.sentimentAnalysis },
    { title: 'Red Flags', text: summary.redFlags },
    { title: 'Web/News Findings', text: summary.webNewsFindings },

    { title: 'Online Behavior', text: summary.digitalBehavior },
    { title: 'Influence', text: summary.digitalInfluence },
    {
      title: 'Recommendations',
      text: summary.recommendationsInsights,
    },
    {
      title: 'Conclusion',
      text: summary.recommentationsConclusion,
    },
    {
      title: 'Improvements',
      text: summary.recommendationsImprovements,
    },
    {
      title: 'Financial Misconduct (Only Adverse Media)',
      text: summary.financialMisconduct,
    },
    {
      title: 'Legal Dispute (Only Adverse Media)',
      text: summary.legalDispute,
    },
    {
      title: 'Criminal Records (Only Adverse Media)',
      text: summary.criminalRecords,
    },
    {
      title: 'Legal Judgments (Only Adverse Media)',
      text: summary.legalJudgments,
    },
    {
      title: 'Positive Mentions (Only Adverse Media)',
      text: summary.positiveMentions,
    },
    {
      title: 'Assessment (Only Adverse Media)',
      text: summary.assessment,
    },
    {
      title: 'Compliance Disclaimer (Only Adverse Media)',
      text: summary.complianceDisclaimer,
    },
    {
      title: 'Adverse Media Summary (Only Adverse Media)',
      text: summary.adverseMediaSummary,
    },
  ]

  return (
    <Card className="flex flex-col p-4 gap-6">
      <CardHeader className="flex flex-row items-center justify-between p-0">
        <CardTitle className="text-neutralColor text-xl font-medium">
          Core Findings
        </CardTitle>
        <Button
          className="shadow-none border-none"
          variant={'outline'}
          size={'icon'}
          disabled
        >
          <Edit2 size="24" color={weCheckPalette.disabled} variant="Linear" />
        </Button>
      </CardHeader>
      <CardContent className="flex flex-col gap-6 p-0">
        {summaryFields.map((field, index) => (
          <SummaryCard key={index} title={field.title} text={field.text} />
        ))}
      </CardContent>
    </Card>
  )
}
