/**
 * Normalizes URLs to ensure consistent format:
 * - Adds https:// if no protocol is present
 * - Adds www. if not present
 * - Removes trailing slashes
 * - Removes multiple consecutive slashes
 *
 * @param url The URL to normalize
 * @returns Normalized URL string
 */
export const normalizeUrl = (url: string): string => {
  if (!url) return ''

  try {
    // Remove trailing slashes and clean up multiple slashes
    let cleanUrl = url
      .trim()
      .replace(/\/+$/, '')
      .replace(/([^:]\/)\/+/g, '$1')

    // Add protocol if missing
    if (!cleanUrl.match(/^[a-zA-Z]+:\/\//)) {
      cleanUrl = 'https://' + cleanUrl
    }

    // Parse the URL
    const urlObj = new URL(cleanUrl)

    // Add www if missing in hostname
    if (!urlObj.hostname.startsWith('www.')) {
      urlObj.hostname = 'www.' + urlObj.hostname
    }

    return urlObj.toString()
  } catch (error) {
    return url
  }
}
