import { ESocialCheckStatus } from '@/store/api/Admin/Admin.types'

export const getStatusText = (status: ESocialCheckStatus | string): string => {
  switch (status) {
    case ESocialCheckStatus.InQueue:
      return 'In Queue'
    case ESocialCheckStatus.Cancelled:
      return 'Cancelled'
    case ESocialCheckStatus.Complete:
      return 'Complete'
    case ESocialCheckStatus.PendingReview:
      return 'Pending Review'
    case ESocialCheckStatus.PendingComplianceSigned:
      return 'Pending Compliance Signed'
    case ESocialCheckStatus.ErrorPdf:
      return 'Error PDF'
    case ESocialCheckStatus.NotReleased:
      return 'Not Released'
    case ESocialCheckStatus.PendingToScrapProfiles:
      return 'Pending To Scrap'
    case ESocialCheckStatus.ScrapeInProgress:
      return 'Scrape In Progress'
    case 'PendingConfirmProfiles':
      return 'Pending Confirm Profiles'
    case ESocialCheckStatus.IaAnalysisInProgress:
      return 'IA Analysis In Progress'
    default:
      return status
  }
}
