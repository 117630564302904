import { createSlice } from '@reduxjs/toolkit'

import type { Enterprise } from './Enterprises.types'

export const enterpriseInitialState: Enterprise = {
  id: '',
  name: '',
  address: '',
  customPdf: false,
  emailOwner: '',
  firstnameOwner: '',
  lastnameOwner: '',
  logo: '',
  displayName: '',
  palletBgColor: '',
  palletHlColor: '',
  palletAcColor: '',
  palletTextColor: '',
  palletTextColor60: '',
  credits: {
    aiReportCredits: 0,
    aiAnalystReportCredits: 0,
    adverseMediaReportCredits: 0,
    assistedReportCredits: 0,
    facialMatchingCredits: 0,
  },
}

const enterpriseSlice = createSlice({
  name: 'enterprise',
  initialState: enterpriseInitialState,
  reducers: {
    setEnterprise: (state, action) => {
      return { ...state, ...action.payload }
    },
    clearEnterprise: () => {
      return enterpriseInitialState
    },
  },
})

export const { setEnterprise, clearEnterprise } = enterpriseSlice.actions

export default enterpriseSlice.reducer
