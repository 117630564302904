import { Edit2 } from 'iconsax-react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { Button } from '@/components/ui/button'
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { AppRoutes } from '@/constants/appRoutes'
import type {
  IOnlineBehavior,
  IPersonAttribute,
  IRedFlag,
  ISentimentAnalysisResponse,
  IStrengthWeakness,
} from '@/store/api/Subjects/Subjects.types'
import { setEditSentimentAnalysisToken } from '@/store/api/Subjects/SubjectsGuards.slice'

import { SummaryCard } from './SummaryCard'

interface DisplaySentimentAnalysisProps {
  summary: ISentimentAnalysisResponse
}

export const DisplaySentimentAnalysis = ({
  summary,
}: DisplaySentimentAnalysisProps) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const renderAttributes = (attributes: IPersonAttribute[]) => {
    return attributes.map((attr) => attr.trait).join(', ')
  }

  const renderBehaviors = (behaviors: IOnlineBehavior[]) => {
    return behaviors.map((b) => b.behavior).join(', ')
  }

  const renderRedFlags = (flags: IRedFlag[]) => {
    return flags.map((f) => f.flag).join(', ')
  }

  const renderStrengthsWeaknesses = (items: IStrengthWeakness[]) => {
    return items.map((item) => item.description).join(', ')
  }

  const summaryFields = [
    {
      title: 'Sentiment Overview',
      text: summary.sentimentAnalysis.overview,
    },
    {
      title: 'Content Radar Level',
      text: summary.contentRadar,
    },
    {
      title: 'Positive Attributes',
      text: renderAttributes(summary.digitalPersonSnapshot.positiveAttributes),
    },
    {
      title: 'Neutral Attributes',
      text: renderAttributes(summary.digitalPersonSnapshot.neutralAttributes),
    },
    {
      title: 'Negative Attributes',
      text: renderAttributes(summary.digitalPersonSnapshot.negativeAttributes),
    },
    {
      title: 'Online Behavior',
      text: renderBehaviors(summary.onlineBehavior),
    },
    {
      title: 'Red Flags',
      text: renderRedFlags(summary.redFlags),
    },
    {
      title: 'Strengths',
      text: renderStrengthsWeaknesses(summary.strengthsAndWeaknesses.strengths),
    },
    {
      title: 'Weaknesses',
      text: renderStrengthsWeaknesses(
        summary.strengthsAndWeaknesses.weaknesses,
      ),
    },
  ]

  const handleNavigate = () => {
    dispatch(setEditSentimentAnalysisToken(true))
    navigate(AppRoutes.editSentimentAnalysis)
  }

  return (
    <Card className="flex flex-col p-4 gap-6">
      <CardHeader className="flex flex-row items-center justify-between p-0">
        <CardTitle className="text-neutralColor text-xl font-medium">
          Sentiment Analysis
        </CardTitle>
        <Button
          className="shadow-none border-none"
          variant={'outline'}
          size={'icon'}
          onClick={handleNavigate}
        >
          <Edit2 size="24" color="#111111" variant="Linear" />
        </Button>
      </CardHeader>
      <CardContent className="flex flex-col gap-6 p-0">
        {summaryFields.map((field, index) => (
          <SummaryCard key={index} title={field.title} text={field.text} />
        ))}
      </CardContent>
    </Card>
  )
}
