import FlatList from 'flatlist-react'
import { isEmpty } from 'ramda'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import websearchPng from '@/assets/socialMediaLogos/webSearchPng.png'
import { EmptyState } from '@/components/EmptyState/EmptyState'
import { LottieSpinner } from '@/components/LottieSpinner/LottieSpinner'
import { Input } from '@/components/ui/input'
import { WeScreen } from '@/components/WeScreen/WeScreen'
import { AppRoutes } from '@/constants/appRoutes'
import { useDebounce } from '@/hooks/useDebounce'
import useMobileMediaQuery from '@/hooks/useMobileMediaQuery'
import useScreenSize from '@/hooks/useScreenSize'
import { useLazyGetSubjectPostsQuery } from '@/store/api/Subjects/Subjects.query'
import {
  selectCurrentPlatform,
  selectSubjectId,
} from '@/store/api/Subjects/Subjects.selector'
import type {
  PlatformType,
  PostsList,
  SocialMedia,
} from '@/store/api/Subjects/Subjects.types'
import {
  setCreatePostToken,
  setEditProfileDataToken,
  setPlatformAndPostsEditionToken,
} from '@/store/api/Subjects/SubjectsGuards.slice'
import { formatDate } from '@/utils/date'

import type { FlaggedPopoverSortType } from './components/PlatformPopover'
import { PlatformPopover } from './components/PlatformPopover'
import { PostCard } from './components/PostCard'
import { ReportDetailsHeader } from './components/ReportDetailsHeader'
import { useHasCurrentScreenToken } from './Guards/SubjectGuards'

const filterPosts = (
  posts: PostsList[],
  searchTerm: string,
  socialMediaName: string,
) => {
  if (!searchTerm && socialMediaName !== 'Adverse Media Posts') return posts

  const lowercasedSearchTerm = searchTerm.toLowerCase()

  return posts.filter((post) => {
    if (
      lowercasedSearchTerm === '' &&
      socialMediaName !== 'Adverse Media Posts'
    ) {
      return true
    }

    const formattedDate = post?.date
      ? formatDate(post.date, 'hh:mm a - MMM dd, yyyy').toLowerCase()
      : ''

    const shouldFilterByNegativeSentiment =
      socialMediaName === 'Adverse Media Posts' &&
      post.sentimentFlag !== 'Negative'

    if (shouldFilterByNegativeSentiment) {
      return false
    }

    return (
      post?.content?.toLowerCase().includes(lowercasedSearchTerm) ||
      post?.platform?.toLowerCase().includes(lowercasedSearchTerm) ||
      post?.type?.toLowerCase().includes(lowercasedSearchTerm) ||
      post?.link?.toLowerCase().includes(lowercasedSearchTerm) ||
      post?.sentimentFlag?.toLowerCase().includes(lowercasedSearchTerm) ||
      post?.flagReason?.toLowerCase().includes(lowercasedSearchTerm) ||
      formattedDate.includes(lowercasedSearchTerm)
    )
  })
}

export const PlatformAndPostsEdition = () => {
  useHasCurrentScreenToken('platformAndPostsEdition')
  const subjectId = useSelector(selectSubjectId)
  const isMobile = useMobileMediaQuery()
  const socialMediaData: SocialMedia = useSelector(selectCurrentPlatform)
    ?.platformData
  const platform: string = useSelector(selectCurrentPlatform).platform
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { height: screenHeigth, width: screenWidth } = useScreenSize()
  const [currentPage, setCurrentPage] = useState(1)
  const [sortFlaggType, setSortFlaggType] =
    useState<FlaggedPopoverSortType>(undefined)
  const [searchTerm, setSearchTerm] = useState('')
  const debouncedSearchTerm = useDebounce(searchTerm, 1200)

  const [
    getPosts,
    {
      data: postData,
      isLoading: isLoadingPostFirstTime,
      isError: isErrorPost,
      isFetching: isFechingPost,
    },
  ] = useLazyGetSubjectPostsQuery()

  const postsList = postData?.info[0]?.list || []

  const filteredPostsList = useMemo(() => {
    const filtered = filterPosts(
      postsList,
      debouncedSearchTerm,
      socialMediaData.name,
    )
    return [...filtered]
      .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
      .slice(0, 2000)
  }, [postsList, debouncedSearchTerm, socialMediaData.name])

  const totalPages = postData?.info[0]?.pages || 1
  const currentPageFromApi = postData?.info[0]?.page || 1

  const makeBeRequest = useCallback(
    (
      page: number,
      currentFilterBy?: 'Positive' | 'Negative' | 'Neutral' | 'Flagged',
    ) => {
      getPosts({
        subjectId: subjectId || '',
        page: page,
        pageSize: 10,
        platform: (platform as PlatformType) || '',
        filterBy: currentFilterBy,
        socialMediaId:
          platform === 'WebSearch' ? undefined : socialMediaData.id,
      })
    },
    [platform, getPosts, subjectId, socialMediaData.id],
  )

  useEffect(() => {
    makeBeRequest(currentPage, sortFlaggType)
  }, [currentPage, sortFlaggType, makeBeRequest])

  const isEmptyArray = isEmpty(filteredPostsList)

  const hasMoreData = useMemo(
    () => currentPageFromApi < totalPages,
    [currentPageFromApi, totalPages],
  )

  const postCardHeigth = useMemo(
    () => Math.floor(screenHeigth / (socialMediaData ? 1.7 : 1.35)),
    [screenHeigth, socialMediaData],
  )

  const postItemHeigth = useMemo(
    () => Math.floor(screenHeigth / 2),
    [screenHeigth],
  )

  const renderPost = (post: PostsList, idx: string) => {
    return (
      <PostCard
        id={`${post.id}-${idx}`}
        post={post}
        cardHeigth={postItemHeigth}
      />
    )
  }

  const fetchMoreData = useCallback(
    (page?: number, sortFlaggedType?: FlaggedPopoverSortType) => {
      const newPage = page ?? currentPage + 1
      if (newPage > totalPages) return
      setCurrentPage(newPage)
      makeBeRequest(newPage, sortFlaggedType)
    },
    [currentPage, makeBeRequest, totalPages],
  )

  const fetchMoreOnPagination = useCallback(() => {
    if (!hasMoreData) return
    const newPage = currentPage + 1
    setCurrentPage(newPage)
    makeBeRequest(newPage, sortFlaggType)
  }, [currentPage, hasMoreData, makeBeRequest, sortFlaggType])

  const isLoading =
    (isFechingPost && currentPage === 1) || isLoadingPostFirstTime

  const handleCancel = () => {
    dispatch(setPlatformAndPostsEditionToken(false))
    navigate(AppRoutes.socialMedia)
  }

  const handleNewPost = () => {
    dispatch(setCreatePostToken(true))
    navigate(AppRoutes.newPost, {
      state: { id: socialMediaData.id, platform: socialMediaData.name },
    })
  }

  const handleEditProfile = () => {
    dispatch(setEditProfileDataToken(true))
    navigate(AppRoutes.editProfileData, {
      state: {
        platform:
          socialMediaData.name === 'X' ? 'Twitter' : socialMediaData.name,
        id: socialMediaData.id,
      },
    })
  }

  return (
    <WeScreen
      isError={isErrorPost}
      isLoading={isLoadingPostFirstTime}
      boxCustomPadding={16}
      useNextButton={false}
      boxCustomGap={24}
      onClickBack={handleCancel}
    >
      {platform === 'WebSearch' ? (
        <ReportDetailsHeader
          fullName={socialMediaData.name}
          profileImage={websearchPng}
          primaryButtonTitle="Add New Post"
          primaryButtonOnClick={handleNewPost}
          imgCustomPadding={34}
          isWebNewOrAdverseMedia
        />
      ) : (
        <ReportDetailsHeader
          fullName={socialMediaData.userName || ''}
          profileImage={socialMediaData.profileImgUrl || ''}
          followers={socialMediaData.followers}
          following={socialMediaData.following}
          posts={socialMediaData.postsCount}
          bio={socialMediaData.bio}
          primaryButtonTitle="Add New Post"
          primaryButtonOnClick={handleNewPost}
          secondaryButtonTitle="Modify Profile Data"
          secondaryButtonOnClick={handleEditProfile}
          id={socialMediaData.id}
          subjectId={subjectId || ''}
          showDelete
          isPrivate={socialMediaData.isPrivate}
        />
      )}

      <div className={`flex justify-between ${isEmptyArray ? 'hidden' : ''}`}>
        <Input
          className="w-[376px] h-10 rounded-lg border-neutralColor30 focus-visible:ring-0"
          placeholder="Search"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        {!isEmptyArray && (
          <PlatformPopover
            sortedBy={sortFlaggType}
            onChangeSort={(sortBy) => {
              setSortFlaggType(sortBy)
              fetchMoreData(1, sortBy)
            }}
          />
        )}
      </div>

      {!isLoading ? (
        <div
          style={{ overflow: 'auto', height: `${postCardHeigth}px` }}
          id="scrolling-container"
        >
          <FlatList
            list={filteredPostsList}
            renderItem={renderPost}
            displayGrid
            minColumnWidth={
              isMobile ? `${screenWidth / 2}px` : `${screenWidth / 3.5}px`
            }
            renderWhenEmpty={() => (
              <div className="flex flex-col">
                {socialMediaData && (
                  <div className="flex  w-full justify-between">
                    <Input
                      className="w-[376px] h-10 rounded-lg border-neutralColor30 focus-visible:ring-0"
                      placeholder="Search"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <PlatformPopover
                      sortedBy={sortFlaggType}
                      onChangeSort={(sortBy) => {
                        setSortFlaggType(sortBy)
                        fetchMoreData(1, sortBy)
                      }}
                    />
                  </div>
                )}
                <div className="flex overflow-hidden items-center justify-center w-full h-[85%]">
                  <EmptyState text="No Results Found. There is no data to display here." />
                </div>
              </div>
            )}
            pagination={{
              hasMore: hasMoreData,
              loadMore: fetchMoreOnPagination,
            }}
          />
        </div>
      ) : null}

      {isLoading && (
        <div className="flex justify-center">
          <LottieSpinner />
        </div>
      )}
    </WeScreen>
  )
}
