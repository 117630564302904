import { zodResolver } from '@hookform/resolvers/zod'
import { SelectSeparator } from '@radix-ui/react-select'
import { useEffect, useRef } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { toast } from 'sonner'

import { Card, CardTitle } from '@/components/ui/card'
import { Form } from '@/components/ui/form'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
} from '@/components/ui/select'
import { catchWithSentry } from '@/hooks/useCatchWithSentry'
import { useChangeReportPdfConfigurationMutation } from '@/store/api/Admin/Admin.query'
import { setReportInfo } from '@/store/api/Admin/Admin.slice'
import type { reportSettingSchemaType } from '@/utils/schemas'
import { reportSettingSchema } from '@/utils/schemas'

import { SwitchCard } from './SwitchCard'

interface ReportSettingsTypes {
  reportInfo: any
}

export const DisplayReportSettings = ({ reportInfo }: ReportSettingsTypes) => {
  const dispatch = useDispatch()
  const [changeReportConfig] = useChangeReportPdfConfigurationMutation()

  const isFirstRender = useRef(true)

  const debounceTimeout = useRef<NodeJS.Timeout | null>(null)

  const form = useForm<reportSettingSchemaType>({
    resolver: zodResolver(reportSettingSchema),
    defaultValues: {
      coreFindings: reportInfo.pdfCoreFindings,
      socialMediaAccounts: reportInfo.pdfSocialMediaAccounts,
      sentimentAnalysis: reportInfo.pdfSentimentAnalysis,
      flaggedBehaviors: reportInfo.pdfFlaggedBehaviors,
      positivePosts: reportInfo.pdfPositivePosts,
      negativePosts: reportInfo.pdfNegativePosts,
      neutralPosts: reportInfo.pdfNeutralPosts,
      webNewsPosts: reportInfo.pdfWebSearchPosts,
      pdfFocusedTopics: reportInfo.pdfFocusedTopics,
      pdfPersonalityAndSkills: reportInfo.pdfPersonalityAndSkills,
      pdfEducationAndCareer: reportInfo.pdfEducationAndCareer,
      language: reportInfo.pdfLanguage,
    },
  })

  const { handleSubmit, setValue, control } = form

  const language = useWatch({
    control,
    name: 'language',
  })

  const settings = useWatch({
    control,
  })

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
      return
    }

    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current)
    }

    debounceTimeout.current = setTimeout(() => {
      handleSubmit(handleChangeReportConfig)()
    }, 2500)

    return () => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current)
      }
    }
  }, [language, settings])

  const handleChangeReportConfig = async (data: any) => {
    try {
      await changeReportConfig({
        subjectId: reportInfo.subjectId,
        pdfLanguage: data.language,
        pdfCoreFindings: data.coreFindings,
        pdfSocialMediaAccounts: data.socialMediaAccounts,
        pdfSentimentAnalysis: data.sentimentAnalysis,
        pdfFlaggedBehaviors: data.flaggedBehaviors,
        pdfPositivePosts: data.positivePosts,
        pdfNegativePosts: data.negativePosts,
        pdfNeutralPosts: data.neutralPosts,
        pdfWebSearchPosts: data.webNewsPosts,
        pdfFocusedTopics: data.pdfFocusedTopics,
        pdfPersonalityAndSkills: data.pdfPersonalityAndSkills,
        pdfEducationAndCareer: data.pdfEducationAndCareer,
      }).unwrap()

      toast.success('Changes saved successfully')
      dispatch(
        setReportInfo({
          subjectId: reportInfo.subjectId,
          pdfLanguage: data.language,
          pdfCoreFindings: data.coreFindings,
          pdfSocialMediaAccounts: data.socialMediaAccounts,
          pdfSentimentAnalysis: data.sentimentAnalysis,
          pdfFlaggedBehaviors: data.flaggedBehaviors,
          pdfPositivePosts: data.positivePosts,
          pdfNegativePosts: data.negativePosts,
          pdfNeutralPosts: data.neutralPosts,
          pdfWebSearchPosts: data.webNewsPosts,
          pdfFocusedTopics: data.pdfFocusedTopics,
          pdfPersonalityAndSkills: data.pdfPersonalityAndSkills,
          pdfEducationAndCareer: data.pdfEducationAndCareer,
        }),
      )
    } catch (error) {
      toast.error('Error saving changes.')
      catchWithSentry(error)
    }
  }

  return (
    <Form {...form}>
      <form className="flex flex-col gap-8">
        <Card className="flex flex-col gap-6 p-4 rounded-xl">
          <CardTitle className="text-neutralColor text-xl font-medium">
            Report Language
          </CardTitle>
          <Select
            value={language}
            onValueChange={(newLanguage) => setValue('language', newLanguage)}
          >
            <SelectTrigger className="w-full border-neutralColor30 border h-[46px]">
              {language === 'es' ? 'Spanish' : 'English'}
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                <SelectLabel className="px-2 py-1.5 text-textNormal text-base font-medium tracking-tight">
                  Select Language
                </SelectLabel>
                <SelectSeparator className="border-t border-neutralColor30" />
                <SelectItem value="es">Spanish</SelectItem>
                <SelectItem value="en">English</SelectItem>
              </SelectGroup>
            </SelectContent>
          </Select>
        </Card>

        <Card className="p-4 rounded-xl flex flex-col gap-6">
          <CardTitle className="text-neutralColor text-xl font-medium">
            Report Settings
          </CardTitle>
          <div className="rounded-xl flex flex-wrap w-full gap-y-6 justify-between">
            <SwitchCard
              title="Social Media Accounts"
              description="Overview of linked social media profiles."
              formName="socialMediaAccounts"
            />
            <SwitchCard
              title="Sentiment Analysis"
              description="Analysis of tone and sentiment in posts."
              formName="sentimentAnalysis"
            />
            <SwitchCard
              title="Flagged Behaviors"
              description="Identified behaviors that need attention."
              formName="flaggedBehaviors"
            />
            <SwitchCard
              title="Core Findings"
              description="Key highlights and insights from the report."
              formName="coreFindings"
            />
            <SwitchCard
              title="Negative Posts"
              description="Posts with negative sentiments and concerns."
              formName="negativePosts"
            />
            <SwitchCard
              title="Web/News Posts"
              description="Relevant posts from web and news sources."
              formName="webNewsPosts"
            />
            <SwitchCard
              title="Positive Posts"
              description="Posts with positive sentiments and feedback."
              formName="positivePosts"
            />
            <SwitchCard
              title="Neutral Posts"
              description="Neutral or balanced posts and interactions."
              formName="neutralPosts"
            />
            <SwitchCard
              title="Focused Topics"
              description="Posts related to specific topics you’ve chosen to track for detailed analysis."
              formName="pdfFocusedTopics"
            />
            <SwitchCard
              title="Personality Insights & Skills"
              description="Display insights into the subject's personality and skills based on available data."
              formName="pdfPersonalityAndSkills"
            />
            <SwitchCard
              title="Career & Education"
              description="Show detailed information about the subject's professional career and educational background."
              formName="pdfEducationAndCareer"
            />
          </div>
        </Card>
      </form>
    </Form>
  )
}
