import { Menu } from 'iconsax-react'
import { useEffect, useState } from 'react'
import { Sidebar } from 'react-pro-sidebar'
import { Outlet, useLocation } from 'react-router-dom'

import backgroundImage1 from '@/assets/wecheckLights/1.png'
import backgroundImage2 from '@/assets/wecheckLights/2.png'
import { Loader } from '@/components/Loader/Loader'
import { Button } from '@/components/ui/button'
import { AppRoutes } from '@/constants/appRoutes'
import env from '@/env/env'
import { useAppDispatch } from '@/hooks/redux'
import { useCheckSession } from '@/hooks/useCheckSession'
import { useDisableBasedOnPermissions } from '@/hooks/useDisableBasedOnPermissions'
import { useUserInfoQuery } from '@/store/api/Users/Users.query'
import {
  selectIsPending,
  selectUserFirstLetter,
  selectUserFullname,
} from '@/store/api/Users/Users.selector'
import { setUserInfo } from '@/store/api/Users/Users.slice'
import { weCheckPalette } from '@/utils/palette/weCheckPalette'

import { SidebarItem } from './components/sidebarItem/SidebarItem'
import { SidebarLogout } from './components/sidebarLogout/sidebarLogout'
import { SidebarUser } from './components/sidebarUser/sidebarUser'
import { WecheckEnviromentDistinction } from './components/WecheckEnviromentDistinction/WecheckEnviromentDistinction'

export const Dashboard = () => {
  const location = useLocation()
  const locationPathname = location.pathname
  const dispatch = useAppDispatch()
  const permissions = useDisableBasedOnPermissions()
  const [broken, setBroken] = useState(false)
  const [toggled, setToggled] = useState(false)
  useCheckSession()
  const enviroment = env.NODE_ENV

  const {
    isLoading: isLoadingUserData,
    data: userData,
    isPending,
    userFullname,
    userFirstLetter,
  } = useUserInfoQuery(undefined, {
    selectFromResult: ({ data, ...rest }) => ({
      ...rest,
      isPending: selectIsPending(data),
      userFullname: selectUserFullname(data),
      userFirstLetter: selectUserFirstLetter(data),
      data,
    }),
  })

  useEffect(() => {
    if (userData) {
      dispatch(setUserInfo(userData))
    }
  }, [userData])

  if (isLoadingUserData || !userData) {
    return (
      <div className="flex h-screen items-center">
        <div className="flex justify-center w-full">
          <Loader />
        </div>
      </div>
    )
  }

  return (
    <div className="h-screen">
      <div className="flex w-full">
        <Sidebar
          toggled={toggled}
          width={'240px'}
          className={`w-full`}
          onBreakPoint={setBroken}
          onBackdropClick={() => setToggled(false)}
          breakPoint="md"
          backgroundColor={weCheckPalette.baseColor}
          style={{ borderColor: weCheckPalette.baseColorHighlight }}
        >
          <div
            className={`flex flex-col h-full w-full ${broken ? 'py-0' : ''}`}
          >
            <WecheckEnviromentDistinction
              enviroment={enviroment}
              collapsed={false}
            />

            <div className="flex flex-col flex-1 gap-3 p-4">
              <SidebarItem
                name={'Reports'}
                isActive={
                  locationPathname === AppRoutes.reports ||
                  locationPathname === AppRoutes.socialMedia ||
                  locationPathname === AppRoutes.modifyPersonalInfo ||
                  locationPathname === AppRoutes.editSentimentAnalysis ||
                  locationPathname === AppRoutes.newSocialMedia ||
                  locationPathname === AppRoutes.newPost ||
                  locationPathname === AppRoutes.platformAndPostsEdition
                }
                linkUrl={AppRoutes.reports}
                disabled={!permissions.handleUsers}
                icon={undefined}
                iconDisabled={undefined}
              />
              <SidebarItem
                name={'Batch Upload'}
                isActive={locationPathname === AppRoutes.batchUpload}
                linkUrl={AppRoutes.batchUpload}
                disabled={false}
                icon={undefined}
                iconDisabled={undefined}
              />
              <SidebarItem
                name={'Companies'}
                isActive={
                  locationPathname === AppRoutes.enterprises ||
                  locationPathname === AppRoutes.companyUsers
                }
                linkUrl={AppRoutes.enterprises}
                disabled={!permissions.handleReports}
                icon={<Menu width={20} height={20} variant="Bold" />}
                iconDisabled={<Menu width={20} height={20} variant="Bold" />}
              />

              <SidebarItem
                name={'Users'}
                isActive={locationPathname === AppRoutes.users}
                linkUrl={AppRoutes.users}
                disabled={!permissions.handleBilling}
                icon={undefined}
                iconDisabled={undefined}
              />

              <SidebarItem
                name={'Topics'}
                isActive={locationPathname === AppRoutes.topics}
                linkUrl={AppRoutes.topics}
                disabled={false}
                icon={undefined}
                iconDisabled={undefined}
              />
            </div>
            <div className="bg-baseColorHighlight ">
              <div className="flex flex-col items-center">
                <SidebarUser
                  mail={userData.email}
                  name={userFullname}
                  userInitials={userFirstLetter}
                  isPending={isPending}
                  collapsed={false}
                />
              </div>
              <SidebarLogout />
            </div>
          </div>
        </Sidebar>
        <main className={`w-full h-screen lg:h-auto`}>
          {broken && (
            <div className="p-4 flex justify-between sticky top-0 z-50 bg-transparent">
              <Button
                asChild
                size={'icon'}
                className="bg-baseColorHighlight"
                variant={'outline'}
                onClick={() => setToggled(!toggled)}
              >
                <div>
                  <Menu width={20} height={20} variant="Bold" />
                </div>
              </Button>
            </div>
          )}
          <div
            className={`relative w-full bg-transparent via-15% overflow-auto md:h-screen`}
          >
            <div
              className="fixed inset-0 bg-cover bg-no-repeat bg-center md:ml-[240px]"
              style={{
                backgroundImage: ` url(${backgroundImage1})`,
                zIndex: -1,
                height: '100%',
              }}
            ></div>
            <div
              className="absolute inset-0 bg-cover bg-no-repeat bg-center animate-bg-scroll"
              style={{
                backgroundImage: `url(${backgroundImage2})`,
                zIndex: -1,
                height: '100%',
              }}
            ></div>

            <div className="relative z-10 h-full">
              <Outlet />
            </div>
          </div>
        </main>
      </div>
    </div>
  )
}
