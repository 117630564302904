import { zodResolver } from '@hookform/resolvers/zod'
import { Flag } from 'iconsax-react'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { toast } from 'sonner'

import { Button } from '@/components/ui/button'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import {
  Sheet,
  SheetTrigger,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetDescription,
  SheetFooter,
} from '@/components/ui/sheet'
import { catchWithSentry } from '@/hooks/useCatchWithSentry'
import { useDisableBasedOnPermissions } from '@/hooks/useDisableBasedOnPermissions'
import { useModifyEnterpriseInformationMutation } from '@/store/api/Enterprises/Enterprises.query'
import { selectEnteprise } from '@/store/api/Enterprises/Enterprises.selector'
import { weCheckDefaultColorPallette } from '@/utils/palette/weCheckPalette'
import { enterprisesSchema } from '@/utils/schemas'
import type { enterprisesSchemaType } from '@/utils/schemas'

interface PdfPreferencesSheetTypes {
  currentLogoUrl: string
  currentWhiteLabelingName: string
  currentBackgroundColor: string
  currentHighlightColor: string
  currentAccentColor: string
  currentTextColor: string
  currentText60Color: string
}

export const PdfPreferencesSheet = ({
  currentLogoUrl,
  currentWhiteLabelingName,
  currentBackgroundColor,
  currentHighlightColor,
  currentAccentColor,
  currentTextColor,
  currentText60Color,
}: PdfPreferencesSheetTypes) => {
  const permissions = useDisableBasedOnPermissions()

  const enterpriseId = useSelector(selectEnteprise).id
  const [modifyEnterprise, { isLoading }] =
    useModifyEnterpriseInformationMutation()

  const form = useForm<enterprisesSchemaType>({
    resolver: zodResolver(enterprisesSchema),
    defaultValues: {
      logoUrl: currentLogoUrl,
      whiteLabelingName: currentWhiteLabelingName,
      backgroundColor: currentBackgroundColor,
      highlightColor: currentHighlightColor,
      accentColor: currentAccentColor,
      textColor: currentTextColor,
      textColor60: currentText60Color,
    },
  })

  const { handleSubmit, control, watch } = form

  const saveChanges = async (data: any) => {
    const requestInfo = {
      id: enterpriseId,
      logoUrl: data.logoUrl,
      displayName: data.whiteLabelingName,
      palletBgColor: data.backgroundColor,
      palletHlColor: data.highlightColor,
      palletAcColor: data.accentColor,
      palletTextColor: data.textColor,
      palletTextColor60: data.textColor60,
      palleteBgChipColor: data.BgChipColor,
      palleteBgChipTextColor: data.BgChipTextColor,
    }
    try {
      await modifyEnterprise(requestInfo).unwrap()
      toast.success('Changes saved successfully.')
    } catch (e) {
      toast.error('Error saving changes.')
      catchWithSentry(e)
    }
  }

  const [previewUrl, setPreviewUrl] = useState<string>(currentLogoUrl)
  const profileImgUrl = watch('logoUrl')

  const backgroundColor = watch('backgroundColor', currentBackgroundColor)
  const highlightColor = watch('highlightColor', currentHighlightColor)
  const accentColor = watch('accentColor', currentAccentColor)
  const textColor = watch('textColor', currentTextColor)
  const textColor60 = watch('textColor60', currentTextColor)

  useEffect(() => {
    if (profileImgUrl?.trim()) {
      setPreviewUrl(profileImgUrl)
    } else {
      setPreviewUrl('')
    }
  }, [profileImgUrl])

  const resetToDefault = () => {
    form.reset({
      logoUrl: weCheckDefaultColorPallette.logoUrl,
      backgroundColor: weCheckDefaultColorPallette.backgroundColor,
      highlightColor: weCheckDefaultColorPallette.highlightColor,
      accentColor: weCheckDefaultColorPallette.accentColor,
      textColor: weCheckDefaultColorPallette.textColor,
      textColor60: weCheckDefaultColorPallette.textColor60,
    })
  }

  return (
    <Sheet>
      <SheetTrigger>
        <Button className="h-8 px-3 py-2 rounded-xl" variant={'secondary'}>
          PDF Preferences
        </Button>
      </SheetTrigger>
      <SheetContent className="flex flex-col overflow-y-scroll justify-between px-4">
        <div className="flex flex-col gap-5">
          <SheetHeader className="flex flex-col gap-3">
            <SheetTitle className="text-xl font-medium text-neutralColor">
              PDF Preferences
            </SheetTitle>
            <SheetDescription className="text-neutralColor60 text-base font-normal">
              Personalize your PDF report with custom colors and branding.
            </SheetDescription>
          </SheetHeader>
          <Form {...form}>
            <form className="flex flex-col gap-8">
              <div className="flex flex-col gap-y-3">
                <FormField
                  disabled={!permissions.handleEnterprises}
                  control={control}
                  name="whiteLabelingName"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Company Name</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage data-name="credits" />
                    </FormItem>
                  )}
                />

                <div className="flex justify-between items-end w-full">
                  <FormField
                    disabled={!permissions.handleEnterprises}
                    control={control}
                    name="logoUrl"
                    render={({ field }) => (
                      <FormItem className="w-[75%]">
                        <FormLabel>Logo URL</FormLabel>
                        <FormControl>
                          <Input {...field} />
                        </FormControl>
                        <FormMessage data-name="credits" />
                      </FormItem>
                    )}
                  />
                  <div className="flex w-[68px] h-[68px] ">
                    <img
                      src={previewUrl}
                      alt="Preview"
                      className="flex w-full h-full object-contain rounded-xl bg-black/30"
                    />
                  </div>
                </div>
              </div>

              <div className="flex flex-col gap-4">
                <p className="text-neutralColor text-base font-medium">
                  PDF Theme
                </p>
                <div className="flex flex-col gap-3">
                  <FormField
                    disabled={!permissions.handleEnterprises}
                    control={control}
                    name="backgroundColor"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Background Color:</FormLabel>
                        <FormControl>
                          <Input
                            {...field}
                            icon={
                              <div className="flex absolute mt-2.5 mr-2.5">
                                <div
                                  className="w-6 h-6 rounded border border-neutralColor30"
                                  style={{ backgroundColor: backgroundColor }}
                                />
                              </div>
                            }
                          />
                        </FormControl>
                        <FormMessage data-name="credits" />
                      </FormItem>
                    )}
                  />
                  <FormField
                    disabled={!permissions.handleEnterprises}
                    control={control}
                    name="highlightColor"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Highlight Color</FormLabel>
                        <FormControl>
                          <Input
                            {...field}
                            icon={
                              <div className="flex absolute mt-2.5 mr-2.5">
                                <div
                                  className="w-6 h-6 rounded border border-neutralColor30"
                                  style={{ backgroundColor: highlightColor }}
                                />
                              </div>
                            }
                          />
                        </FormControl>
                        <FormMessage data-name="credits" />
                      </FormItem>
                    )}
                  />

                  <FormField
                    disabled={!permissions.handleEnterprises}
                    control={control}
                    name="accentColor"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Accent Color</FormLabel>
                        <FormControl>
                          <Input
                            {...field}
                            icon={
                              <div className="flex absolute mt-2.5 mr-2.5">
                                <div
                                  className="w-6 h-6 rounded border border-neutralColor30"
                                  style={{ backgroundColor: accentColor }}
                                />
                              </div>
                            }
                          />
                        </FormControl>
                        <FormMessage data-name="credits" />
                      </FormItem>
                    )}
                  />
                  <FormField
                    disabled={!permissions.handleEnterprises}
                    control={control}
                    name="textColor"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Text Color</FormLabel>
                        <FormControl>
                          <Input
                            {...field}
                            icon={
                              <div className="flex absolute mt-2.5 mr-2.5">
                                <div
                                  className="w-6 h-6 rounded border border-neutralColor30"
                                  style={{ backgroundColor: textColor }}
                                />
                              </div>
                            }
                          />
                        </FormControl>
                        <FormMessage data-name="credits" />
                      </FormItem>
                    )}
                  />
                  <FormField
                    disabled={!permissions.handleEnterprises}
                    control={control}
                    name="textColor60"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Text 60% Color</FormLabel>
                        <FormControl>
                          <Input
                            {...field}
                            icon={
                              <div className="flex absolute mt-2.5 mr-2.5">
                                <div
                                  className="w-6 h-6 rounded border border-neutralColor30"
                                  style={{ backgroundColor: textColor60 }}
                                />
                              </div>
                            }
                          />
                        </FormControl>
                        <FormMessage data-name="credits" />
                      </FormItem>
                    )}
                  />
                </div>
                <Button
                  type="button"
                  variant="secondary"
                  className="h-8 rounded-lg w-min"
                  onClick={resetToDefault}
                >
                  <p className="text-xs font-medium">Reset to Default</p>
                </Button>
              </div>

              <div className="flex flex-col gap-4">
                <p className="text-neutralColor text-base font-medium">
                  Preview
                </p>
                <div className="flex flex-col gap-3 text-neutral-800 text-sm font-medium">
                  <div className="p-3" style={{ backgroundColor }}>
                    <p style={{ color: accentColor, marginBottom: 5 }}>
                      Accent Color
                    </p>
                    <div
                      className="flex flex-row justify-between p-3"
                      style={{ backgroundColor: highlightColor }}
                    >
                      <div>
                        <div className="flex flex-row gap-2">
                          <p style={{ color: textColor }}>Text Color</p>
                          <Flag size="16" variant="Bold" color={accentColor} />
                        </div>

                        <p style={{ color: textColor60 }}>Text 60% Color</p>
                      </div>
                      <div>
                        <div className="flex flex-row gap-4">
                          <div
                            className="flex rounded-xl border-[0.5px] py-1 px-2"
                            style={{ backgroundColor: '#C6F5FF' }}
                          >
                            <p className="text-xs" style={{ color: '#037797' }}>
                              Chip Test
                            </p>
                          </div>
                          <div
                            className="flex rounded-xl border-[0.5px] py-1 px-2"
                            style={{ backgroundColor: '#FFCCCC' }}
                          >
                            <p className="text-xs" style={{ color: '#853030' }}>
                              Chip Test
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </Form>
        </div>
        <SheetFooter>
          <Button variant={'secondary'} className="w-full">
            Discard Changes
          </Button>
          <Button
            onClick={handleSubmit(saveChanges)}
            isLoading={isLoading}
            className="w-full"
          >
            Confirm Changes
          </Button>
        </SheetFooter>
      </SheetContent>
    </Sheet>
  )
}
