import { zodResolver } from '@hookform/resolvers/zod'
import { isEmpty } from 'ramda'
import React, { useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useForm } from 'react-hook-form'
import { toast } from 'sonner'

import { Button } from '@/components/ui/button'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { useUpdateDigitalFootprintMutation } from '@/store/api/Scrape/Scrape.query'
import { EDigitalFootprintStatus } from '@/store/api/Scrape/Scrape.types'
import type { PersonData } from '@/store/api/Subjects/Subjects.types'
import { filterEmptyFields } from '@/utils/filterEmptyFields'
import type { createNewFootPrintSchemaType } from '@/utils/schemas'
import { createNewFootPrintSchema } from '@/utils/schemas'

const DigitalFootprintLoader = ({
  subjectId,
}: {
  subjectId: string
  personData?: PersonData
}) => {
  const [selectedFile, setSelectedFile] = useState<File | undefined>()
  const [imagePreview, setImagePreview] = useState<string | null>(null)

  const form = useForm<createNewFootPrintSchemaType>({
    resolver: zodResolver(createNewFootPrintSchema),
    defaultValues: {
      platform: '',
      userName: '',
      description: '',
      url: '',
      mediaUrl: '',
    },
    mode: 'onChange',
  })

  const { handleSubmit, formState, reset } = form

  const [updateDigitalFootprint, { isLoading }] =
    useUpdateDigitalFootprintMutation()

  const OnSubmit = async (data: any) => {
    try {
      const formData = new FormData()
      // Agregar la imagen si existe
      if (selectedFile) {
        formData.append('image', selectedFile)
      }

      const dataObject: any = {
        url: data.url,
        platform: data.platform,
        status: EDigitalFootprintStatus.CHECKED,
        description: data.description,
        userName: data.userName,
        isWebSearch: data.isWebSearch,
      }

      const filteredDataObject = filterEmptyFields(dataObject)
      formData.append('data', JSON.stringify(filteredDataObject))

      await updateDigitalFootprint({
        formData,
        subjectId: subjectId,
      }).unwrap()

      toast.success('Estado del perfil actualizado correctamente')

      reset()
      setSelectedFile(undefined)
      setImagePreview(null)
    } catch (error) {
      toast.error('Fallo al subir el digital footprint')
      console.error('Error en la mutación:', error)
    }
  }

  const onDropRejected = (e: any) => {
    if (
      !isEmpty(e) &&
      e[0].errors &&
      e[0].errors[0].code === 'file-too-large'
    ) {
      toast.error('File is too large')
    } else {
      toast.error('Invalid file type')
    }
  }

  const onDrop = useCallback(async (acceptedFiles: any) => {
    if (!isEmpty(acceptedFiles)) {
      const file = acceptedFiles[0]
      setSelectedFile(file)
      setImagePreview(URL.createObjectURL(file))
    }
  }, [])

  const { getRootProps, getInputProps, inputRef } = useDropzone({
    onDrop,
    onDropRejected,
    disabled: false,
    noClick: true,
    accept: {
      'image/png': ['.png', '.jpg', '.webp', '.jpeg'],
    },
    maxFiles: 1,
    maxSize: 5000000,
  })

  return (
    <div className="flex flex-col gap-4">
      <h2>Upload Digital Footprint</h2>

      <Form {...form}>
        <form className="flex flex-col gap-6">
          <FormField
            name="url"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input {...field} placeholder="URL" />
                </FormControl>
                <FormMessage data-name="videoLink" />
              </FormItem>
            )}
          />
          <FormField
            name="description"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input {...field} placeholder="Description" />
                </FormControl>
                <FormMessage data-name="videoLink" />
              </FormItem>
            )}
          />

          <FormField
            name="userName"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input {...field} placeholder="Username" />
                </FormControl>
                <FormMessage data-name="videoLink" />
              </FormItem>
            )}
          />

          <FormField
            name="platform"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input {...field} placeholder="Platform" />
                </FormControl>
                <FormMessage data-name="videoLink" />
              </FormItem>
            )}
          />

          <FormField
            name="mediaUrl"
            render={() => (
              <FormItem className="">
                <div className="flex gap-4">
                  <FormControl className="flex justify-between">
                    <div
                      {...getRootProps()}
                      className="flex h-11 w-full rounded-md border border-neutralColor30 bg-baseColorHighlight px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-neutralColor60 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50"
                    >
                      <input {...getInputProps()} />
                      {selectedFile ? (
                        <div className="flex items-center gap-2">
                          <span className="text-sm">{selectedFile.name}</span>
                          <Button
                            variant="ghost"
                            size="sm"
                            onClick={(e) => {
                              e.stopPropagation()
                              setSelectedFile(undefined)
                              setImagePreview(null)
                            }}
                          >
                            Remove
                          </Button>
                        </div>
                      ) : (
                        <div className="flex items-center gap-2">
                          <p className="text-sm text-neutralColor60">
                            Media Image: Drag & drop or click to select
                          </p>
                          <Button
                            type="button"
                            variant="secondary"
                            size="sm"
                            onClick={() => inputRef.current?.click()}
                          >
                            Choose File
                          </Button>
                        </div>
                      )}
                    </div>
                  </FormControl>
                  {imagePreview && (
                    <div className="w-11 h-11 rounded-md overflow-hidden">
                      <img
                        src={imagePreview}
                        alt="Preview"
                        className="w-full h-full object-cover"
                      />
                    </div>
                  )}
                </div>
                <FormMessage data-name="mediaUrl" />
              </FormItem>
            )}
          />

          <Button
            type="submit"
            form="form"
            onClick={handleSubmit(OnSubmit)}
            disabled={!formState.isValid}
            isLoading={isLoading}
          >
            Add Digital Footprint
          </Button>
        </form>
      </Form>
    </div>
  )
}

export default DigitalFootprintLoader
