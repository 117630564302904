import { zodResolver } from '@hookform/resolvers/zod'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { toast } from 'sonner'

import AdverseMediaIcon from '@/assets/credits/adverse.svg?react'
import AiAnalystReportIcon from '@/assets/credits/ai+analyst.svg?react'
import AiReportIcon from '@/assets/credits/ai.svg?react'
import WeCheckAssistedIcon from '@/assets/credits/assisted.svg?react'
import FacialMatchingIcon from '@/assets/credits/facial.svg?react'
import { Button } from '@/components/ui/button'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@/components/ui/sheet'
import { catchWithSentry } from '@/hooks/useCatchWithSentry'
import { selectEnteprise } from '@/store/api/Enterprises/Enterprises.selector'
import type { IEnterpriseCredit } from '@/store/api/Enterprises/Enterprises.types'
import { useAddCreditsMutation } from '@/store/api/Payments/Payments.query'
import type { creditManagementSchemaType } from '@/utils/schemas'
import { creditManagementSchema } from '@/utils/schemas'

export const CreditManagementSheet = ({
  currentCredits,
}: {
  currentCredits: IEnterpriseCredit
}) => {
  const enterpriseId = useSelector(selectEnteprise).id
  const [addCredits, { isLoading }] = useAddCreditsMutation()

  const form = useForm<creditManagementSchemaType>({
    resolver: zodResolver(creditManagementSchema),
    defaultValues: {
      weCheckAssisted: '0',
      aiAnalystReport: '0',
      aiReport: '0',
      adverseMedia: '0',
      facialMatching: '0',
    },
  })

  const { handleSubmit, control } = form

  const onSubmit = async (data: any) => {
    const requestInfo = {
      id: enterpriseId,
      assistedReportCredits: Number(data.weCheckAssisted),
      aiAnalystReportCredits: Number(data.aiAnalystReport),
      aiReportCredits: Number(data.aiReport),
      adverseMediaReportCredits: Number(data.adverseMedia),
      facialMatchingCredits: Number(data.facialMatching),
    }
    try {
      await addCredits(requestInfo).unwrap()
      form.reset()
      toast.success('Credits added successfully.')
    } catch (e) {
      toast.error('Error adding credits.')
      catchWithSentry(e)
    }
  }

  return (
    <Sheet>
      <SheetTrigger>
        <Button className="h-8 px-3 py-2 rounded-xl">Credit Management</Button>
      </SheetTrigger>
      <SheetContent className="flex flex-col overflow-y-scroll px-4 justify-between">
        <div className="flex flex-col gap-6">
          <SheetHeader className="flex flex-col gap-3">
            <SheetTitle className="text-xl font-medium text-neutralColor">
              Credit Management
            </SheetTitle>
            <SheetDescription className="flex flex-col gap-3">
              <div className="flex flex-col gap-1">
                <p className="text-neutralColor80 text-base font-bold">
                  Add Credits
                </p>
                <p className="text-neutralColor60 text-base font-normal">
                  Enter a positive number (e.g., "8") to increase credits.
                </p>
              </div>
              <div className="flex flex-col gap-1">
                <p className="text-neutralColor80 text-base font-bold">
                  Deduct Credits
                </p>
                <p className="text-neutralColor60 text-base font-normal">
                  Enter a negative number (e.g., "-8") to decrease credits.
                </p>
              </div>
            </SheetDescription>
          </SheetHeader>
          <Form {...form}>
            <form className="flex flex-col gap-6">
              <p className="text-neutralColor80 text-base font-medium">
                Available Credits
              </p>
              <FormField
                control={control}
                name="weCheckAssisted"
                render={({ field }) => (
                  <FormItem className="flex flex-col gap-2">
                    <div className="flex justify-between items-center">
                      <div className="flex items-center gap-2">
                        <WeCheckAssistedIcon className="w-8 h-8" />
                        <FormLabel className="text-base">
                          Online Footprint
                        </FormLabel>
                      </div>
                      <p className="text-neutralColor text-base font-bold">
                        {currentCredits.assistedReportCredits}
                      </p>
                    </div>
                    <FormControl>
                      <Input
                        {...field}
                        className="h-10 rounded-lg border-neutralColor60"
                      />
                    </FormControl>
                    <FormMessage data-name="wecheckAssisted" />
                  </FormItem>
                )}
              />
              <FormField
                control={control}
                name="aiAnalystReport"
                render={({ field }) => (
                  <FormItem className="flex flex-col gap-2">
                    <div className="flex justify-between items-center">
                      <div className="flex items-center gap-2">
                        <AiAnalystReportIcon className="w-8 h-8" />
                        <FormLabel className="text-base">
                          AI + Analyst Report
                        </FormLabel>
                      </div>
                      <p className="text-neutralColor text-base font-bold">
                        {currentCredits.aiAnalystReportCredits}
                      </p>
                    </div>
                    <FormControl>
                      <Input
                        {...field}
                        className="h-10 rounded-lg border-neutralColor60"
                      />
                    </FormControl>
                    <FormMessage data-name="aiAnalystReport" />
                  </FormItem>
                )}
              />
              <FormField
                control={control}
                name="aiReport"
                render={({ field }) => (
                  <FormItem className="flex flex-col gap-2">
                    <div className="flex justify-between items-center">
                      <div className="flex items-center gap-2">
                        <AiReportIcon className="w-8 h-8" />
                        <FormLabel className="text-base">AI Report</FormLabel>
                      </div>
                      <p className="text-neutralColor text-base font-bold">
                        {currentCredits.aiReportCredits}
                      </p>
                    </div>
                    <FormControl>
                      <Input
                        {...field}
                        className="h-10 rounded-lg border-neutralColor60"
                      />
                    </FormControl>
                    <FormMessage data-name="aiReport" />
                  </FormItem>
                )}
              />
              <FormField
                control={control}
                name="adverseMedia"
                render={({ field }) => (
                  <FormItem className="flex flex-col gap-2">
                    <div className="flex justify-between items-center">
                      <div className="flex items-center gap-2">
                        <AdverseMediaIcon className="w-8 h-8" />
                        <FormLabel className="text-base">
                          Adverse Media
                        </FormLabel>
                      </div>
                      <p className="text-neutralColor text-base font-bold">
                        {currentCredits.adverseMediaReportCredits}
                      </p>
                    </div>
                    <FormControl>
                      <Input
                        {...field}
                        className="h-10 rounded-lg border-neutralColor60"
                      />
                    </FormControl>
                    <FormMessage data-name="adverseMedia" />
                  </FormItem>
                )}
              />
              <FormField
                control={control}
                name="facialMatching"
                render={({ field }) => (
                  <FormItem className="flex flex-col gap-2">
                    <div className="flex justify-between items-center">
                      <div className="flex items-center gap-2">
                        <FacialMatchingIcon className="h-8 w-8" />
                        <FormLabel className="text-base">
                          Facial Matching
                        </FormLabel>
                      </div>
                      <p className="text-neutralColor text-base font-bold">
                        {currentCredits.facialMatchingCredits}
                      </p>
                    </div>
                    <FormControl>
                      <Input
                        {...field}
                        className="h-10 rounded-lg border-neutralColor60"
                      />
                    </FormControl>
                    <FormMessage data-name="facialMatching" />
                  </FormItem>
                )}
              />
            </form>
          </Form>
        </div>
        <SheetFooter>
          <Button variant={'secondary'} className="w-full">
            Discard Changes
          </Button>
          <Button
            onClick={handleSubmit(onSubmit)}
            isLoading={isLoading}
            className="w-full"
          >
            Confirm Changes
          </Button>
        </SheetFooter>
      </SheetContent>
    </Sheet>
  )
}
