// ... existing code ...

import { zodResolver } from '@hookform/resolvers/zod'
import { useForm, useFormState } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'sonner'

import { Button } from '@/components/ui/button'
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
} from '@/components/ui/select'
import { Textarea } from '@/components/ui/text-area'
import { WeScreen } from '@/components/WeScreen/WeScreen'
import { AppRoutes } from '@/constants/appRoutes'
import { useModifySummaryMutation } from '@/store/api/Subjects/Subjects.query'
import {
  selectSubjectId,
  selectSummary,
} from '@/store/api/Subjects/Subjects.selector'
import type { ISentimentAnalysisResponse } from '@/store/api/Subjects/Subjects.types'
import { setEditSentimentAnalysisToken } from '@/store/api/Subjects/SubjectsGuards.slice'
import type { modifySummarySchemaType } from '@/utils/schemas'
import { modifySummarySchema } from '@/utils/schemas'

import { useHasCurrentScreenToken } from './Guards/SubjectGuards'

export const EditSentimentAnalysis = () => {
  useHasCurrentScreenToken('editSentimentAnalysis')
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const subjectId = useSelector(selectSubjectId)
  const currentSummary = useSelector(
    selectSummary,
  ) as ISentimentAnalysisResponse
  const [modifySummary, { isLoading }] = useModifySummaryMutation()

  const form = useForm<modifySummarySchemaType>({
    resolver: zodResolver(modifySummarySchema),
    defaultValues: {
      sentimentAnalysis: {
        overview: currentSummary?.sentimentAnalysis?.overview || '',
      },
      contentRadar: currentSummary?.contentRadar || 'low',
      positiveAttributes:
        currentSummary?.digitalPersonSnapshot?.positiveAttributes || [],
      neutralAttributes:
        currentSummary?.digitalPersonSnapshot?.neutralAttributes || [],
      negativeAttributes:
        currentSummary?.digitalPersonSnapshot?.negativeAttributes || [],
      onlineBehavior: currentSummary?.onlineBehavior || [],
      redFlags: currentSummary?.redFlags || [],
      strengths: currentSummary?.strengthsAndWeaknesses?.strengths || [],
      weaknesses: currentSummary?.strengthsAndWeaknesses?.weaknesses || [],
      personalityInsights: currentSummary?.personalityInsights || [],
    },
    mode: 'onChange',
  })

  const { handleSubmit, control, setValue } = form
  const { isValid } = useFormState({ control })

  const handleModifySummary = async (data: any) => {
    const payload: ISentimentAnalysisResponse = {
      sentimentAnalysis: data.sentimentAnalysis,
      contentRadar: data.contentRadar,
      digitalPersonSnapshot: {
        positiveAttributes: data.positiveAttributes,
        neutralAttributes: data.neutralAttributes,
        negativeAttributes: data.negativeAttributes,
      },
      onlineBehavior: data.onlineBehavior,
      redFlags: data.redFlags,
      strengthsAndWeaknesses: {
        strengths: data.strengths,
        weaknesses: data.weaknesses,
      },
      personalityInsights: data.personalityInsights,
    }

    try {
      await modifySummary({
        subjectId: subjectId || '',
        ...payload,
      }).unwrap()
      toast.success('Sentiment Analysis modified successfully.')
      dispatch(setEditSentimentAnalysisToken(false))
      navigate(AppRoutes.socialMedia)
    } catch (e) {
      toast.error('Error modifying Sentiment Analysis.')
      console.error(e)
    }
  }

  const onSubmit = (data: any) => {
    handleModifySummary(data)
  }

  const handleCancel = () => {
    dispatch(setEditSentimentAnalysisToken(false))
    navigate(AppRoutes.socialMedia)
  }

  return (
    <WeScreen
      isLoading={isLoading}
      useNextButton={false}
      boxCustomPadding={24}
      containerCustomGap={0}
      rightSideHeaderContent={
        <div className="flex gap-3 items center">
          <Button variant={'secondary'} onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            type="submit"
            onClick={handleSubmit(onSubmit)}
            disabled={!isValid}
          >
            Save
          </Button>
        </div>
      }
    >
      <Form {...form}>
        <form
          className="flex flex-wrap justify-between gap-y-6"
          onSubmit={handleSubmit(onSubmit)}
        >
          <FormField
            control={control}
            name="sentimentAnalysis.overview"
            render={({ field }) => (
              <FormItem className="w-[49%]">
                <FormLabel className="font-medium tracking-tight">
                  Sentiment Overview
                </FormLabel>
                <FormControl>
                  <Textarea
                    {...field}
                    className="bg-baseColorHighlight border border-neutralColor60 rounded-lg"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={control}
            name="contentRadar"
            render={({ field }) => (
              <FormItem className="w-[49%]">
                <FormLabel className="font-medium tracking-tight">
                  Content Radar Level
                </FormLabel>
                <FormControl>
                  <Select
                    {...field}
                    onValueChange={(value: 'high' | 'medium' | 'low') =>
                      setValue('contentRadar', value)
                    }
                  >
                    <SelectTrigger className="border border-neutralColor60">
                      {field.value}
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="low">Low</SelectItem>
                      <SelectItem value="medium">Medium</SelectItem>
                      <SelectItem value="high">High</SelectItem>
                    </SelectContent>
                  </Select>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={control}
            name="positiveAttributes"
            render={({ field }) => (
              <FormItem className="w-[49%]">
                <FormLabel className="font-medium tracking-tight">
                  Positive Attributes
                </FormLabel>
                <FormControl>
                  <Textarea
                    value={field.value.map((t) => t.trait).join(', ')}
                    onChange={(e) => {
                      const traits = e.target.value.split(',').map((trait) => ({
                        trait: trait.trim(),
                      }))
                      field.onChange(traits)
                    }}
                    className="bg-baseColorHighlight border border-neutralColor60 rounded-lg"
                  />
                </FormControl>
                <FormDescription>
                  Enter attributes separated by commas
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />{' '}
          <FormField
            control={control}
            name="neutralAttributes"
            render={({ field }) => (
              <FormItem className="w-[49%]">
                <FormLabel className="font-medium tracking-tight">
                  Neutral Attributes
                </FormLabel>
                <FormControl>
                  <Textarea
                    value={field.value.map((t) => t.trait).join(', ')}
                    onChange={(e) => {
                      const traits = e.target.value.split(',').map((trait) => ({
                        trait: trait.trim(),
                      }))
                      field.onChange(traits)
                    }}
                    className="bg-baseColorHighlight border border-neutralColor60 rounded-lg"
                    placeholder="Enter attributes separated by commas"
                  />
                </FormControl>
                <FormDescription>
                  Enter attributes separated by commas
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />{' '}
          <FormField
            control={control}
            name="negativeAttributes"
            render={({ field }) => (
              <FormItem className="w-[49%]">
                <FormLabel className="font-medium tracking-tight">
                  Negative Attributes
                </FormLabel>
                <FormControl>
                  <Textarea
                    value={field.value.map((t) => t.trait).join(', ')}
                    onChange={(e) => {
                      const traits = e.target.value.split(',').map((trait) => ({
                        trait: trait.trim(),
                      }))
                      field.onChange(traits)
                    }}
                    className="bg-baseColorHighlight border border-neutralColor60 rounded-lg"
                    placeholder="Enter attributes separated by commas"
                  />
                </FormControl>
                <FormDescription>
                  Enter attributes separated by commas
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
          {/* Online Behavior */}
          <FormField
            control={control}
            name="onlineBehavior"
            render={({ field }) => (
              <FormItem className="w-[49%]">
                <FormLabel className="font-medium tracking-tight">
                  Online Behavior
                </FormLabel>
                <FormControl>
                  <Textarea
                    value={field.value.map((b) => b.behavior).join(', ')}
                    onChange={(e) => {
                      const behaviors = e.target.value
                        .split(',')
                        .map((behavior) => ({
                          behavior: behavior.trim(),
                        }))
                      field.onChange(behaviors)
                    }}
                    className="bg-baseColorHighlight border border-neutralColor60 rounded-lg"
                  />
                </FormControl>
                <FormDescription>
                  Enter behaviors separated by commas
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
          {/* Red Flags */}
          <FormField
            control={control}
            name="redFlags"
            render={({ field }) => (
              <FormItem className="w-[49%]">
                <FormLabel className="font-medium tracking-tight">
                  Red Flags
                </FormLabel>
                <FormControl>
                  <Textarea
                    value={field.value.map((f) => f.flag).join(', ')}
                    onChange={(e) => {
                      const flags = e.target.value.split(',').map((flag) => ({
                        flag: flag.trim(),
                      }))
                      field.onChange(flags)
                    }}
                    className="bg-baseColorHighlight border border-neutralColor60 rounded-lg"
                  />
                </FormControl>
                <FormDescription>
                  Enter red flags separated by commas
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={control}
            name="strengths"
            render={({ field }) => (
              <FormItem className="w-[49%]">
                <FormLabel className="font-medium tracking-tight">
                  Strengths
                </FormLabel>
                <FormControl>
                  <Textarea
                    value={field.value.map((d) => d.description).join(', ')}
                    onChange={(e) => {
                      const description = e.target.value
                        .split(',')
                        .map((description) => ({
                          description: description.trim(),
                        }))
                      field.onChange(description)
                    }}
                    className="bg-baseColorHighlight border border-neutralColor60 rounded-lg"
                  />
                </FormControl>
                <FormMessage />
                <FormDescription>
                  Enter strengths separated by commas
                </FormDescription>
              </FormItem>
            )}
          />
          <FormField
            control={control}
            name="weaknesses"
            render={({ field }) => (
              <FormItem className="w-[49%]">
                <FormLabel className="font-medium tracking-tight">
                  Weaknesses
                </FormLabel>
                <FormControl>
                  <Textarea
                    value={field.value.map((d) => d.description).join(', ')}
                    onChange={(e) => {
                      const description = e.target.value
                        .split(',')
                        .map((description) => ({
                          description: description.trim(),
                        }))
                      field.onChange(description)
                    }}
                    className="bg-baseColorHighlight border border-neutralColor60 rounded-lg"
                  />
                </FormControl>
                <FormMessage />
                <FormDescription>
                  Enter weaknesses separated by commas
                </FormDescription>
              </FormItem>
            )}
          />
          <FormField
            control={control}
            name="personalityInsights"
            render={({ field }) => (
              <FormItem className="w-[49%]">
                <FormLabel className="font-medium tracking-tight">
                  Personality Insights
                </FormLabel>
                <FormControl>
                  <Textarea
                    value={field.value.map((t) => t.trait).join(', ')}
                    onChange={(e) => {
                      const traits = e.target.value.split(',').map((trait) => ({
                        trait: trait.trim(),
                      }))
                      field.onChange(traits)
                    }}
                    className="bg-baseColorHighlight border border-neutralColor60 rounded-lg"
                  />
                </FormControl>
                <FormMessage />
                <FormDescription>
                  Enter traits separated by commas
                </FormDescription>
              </FormItem>
            )}
          />
        </form>
      </Form>
    </WeScreen>
  )
}
