import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { AppRoutes } from '@/constants/appRoutes'
import { useAppSelector } from '@/hooks/redux'
import { selectSubjectsGuardsToken } from '@/store/api/Subjects/Subjects.selector'

export const useHasCurrentScreenToken = (
  step:
    | 'editSubject'
    | 'editSentimentAnalysis'
    | 'createPlatform'
    | 'editSubjectInfo'
    | 'platformAndPostsEdition'
    | 'editProfileData'
    | 'editPost'
    | 'createPost',
) => {
  const navigate = useNavigate()
  const guardsTokens = useAppSelector(selectSubjectsGuardsToken)
  const tokenSelector = {
    editSubject: guardsTokens.editSubjectToken,
    editSentimentAnalysis: guardsTokens.editSentimentAnalysisToken,
    createPlatform: guardsTokens.createPlatformToken,
    editSubjectInfo: guardsTokens.editSubjectInfoToken,
    platformAndPostsEdition: guardsTokens.platformAndPostsEditionToken,
    editProfileData: guardsTokens.editProfileDataToken,
    editPost: guardsTokens.editPostToken,
    createPost: guardsTokens.createPostToken,
  }[step]

  useEffect(() => {
    if (!tokenSelector) {
      navigate(AppRoutes.dashboard)
    }
  }, [])
}
