import { useState } from 'react'
import { toast } from 'sonner'

import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import { Input } from '@/components/ui/input'
import {
  Select,
  SelectSeparator,
  SelectItem,
  SelectContent,
  SelectGroup,
  SelectLabel,
  SelectValue,
  SelectTrigger,
} from '@/components/ui/select'
import { catchWithSentry } from '@/hooks/useCatchWithSentry'
import { useUpdateReportOwnerMutation } from '@/store/api/Admin/Admin.query'
import type { Team } from '@/store/api/Enterprises/Enterprises.types'
interface AssignReportPopUpTypes {
  enterpriseId: string
  userId: string
  userTeams: Team[]
}

export const AssignReportPopUp = ({
  enterpriseId,
  userId,
  userTeams,
}: AssignReportPopUpTypes) => {
  const [selectedTeam, setSelectedTeam] = useState('')
  const [subjectId, setSubjectId] = useState('')
  const [open, setOpen] = useState(false)
  const [updateReportOwner, { isLoading }] = useUpdateReportOwnerMutation()

  const assignReport = async () => {
    try {
      await updateReportOwner({
        enterpriseId: enterpriseId,
        userId: userId,
        teamId: selectedTeam,
        subjectId: subjectId,
      })
      toast.success('Report assigned successfully')
      setOpen(false)
    } catch (error) {
      catchWithSentry(error)
      toast.error('Error assigning report')
    }
  }

  const handleTeamSelection = (value: string) => {
    setSelectedTeam(value)
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger className="relative flex w-full cursor-default select-none items-center rounded-sm py-1.5 pl-2 pr-8 outline-none focus:bg-primaryColor5 focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50 h-[35px] p-2 text-neutralColor60 text-sm font-medium tracking-tight hover:bg-primaryColor5">
        Assign Report
      </DialogTrigger>
      <DialogContent maxWidth="max-w-3xl flex flex-col gap-5">
        <DialogHeader className="flex gap-3">
          <DialogTitle className="text-2xl font-medium text-neutralColor">
            Assign a report to this user
          </DialogTitle>
          <DialogDescription className="text-base font-normal text-neutralColor">
            <b>Enter the subject id </b>of the report you want to assign, then
            <b> select one of the teams</b> the user belongs to and
            <b> click 'Assign' button</b> to complete the assignment.
          </DialogDescription>
        </DialogHeader>

        <div className="flex flex-col gap-3">
          <Input
            placeholder="Enter report ID"
            className="w-full border border-neutralColor30 rounded-lg"
            value={subjectId}
            onChange={(e: any) => setSubjectId(e.target.value)}
            disabled={isLoading}
          />
          <Select
            onValueChange={handleTeamSelection}
            value={selectedTeam}
            disabled={subjectId === '' || isLoading}
          >
            <SelectTrigger className="w-full border border-neutralColor30 rounded-lg">
              <SelectValue placeholder="Select team" />
            </SelectTrigger>
            <SelectContent className="w-[224px]">
              <SelectGroup>
                <SelectLabel>Teams</SelectLabel>
                <SelectSeparator />
                {userTeams.map((team: Team, index: number) => (
                  <SelectItem value={team.id} key={index}>
                    {team.name}
                  </SelectItem>
                ))}
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>

        <DialogFooter>
          <DialogClose asChild>
            <Button type="button" className="h-[46px] px-5 py-3 rounded-xl">
              <p className="text-base font-medium">Cancel</p>
            </Button>
          </DialogClose>
          <Button
            isLoading={isLoading}
            disabled={selectedTeam === '' || subjectId === ''}
            onClick={assignReport}
            type="button"
            variant="secondary"
            className="h-[46px] px-5 py-3 rounded-xl"
          >
            <p className="text-neutralColor text-base font-medium">Assign</p>
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
