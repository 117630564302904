import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import emptyAvatar from '@/assets/avatarEmptyState.svg'
import { Button } from '@/components/ui/button'
import { WeScreen } from '@/components/WeScreen/WeScreen'
import { AppRoutes } from '@/constants/appRoutes'
import { selectReportInfo } from '@/store/api/Admin/Admin.selector'
import { useLazyGetSubjectQuery } from '@/store/api/Subjects/Subjects.query'
import { setSubjectInfo } from '@/store/api/Subjects/Subjects.slice'
import type { ISentimentAnalysisResponse } from '@/store/api/Subjects/Subjects.types'
import { setEditSubjectToken } from '@/store/api/Subjects/SubjectsGuards.slice'

import { DisplayCoreFindings } from './components/DisplayCoreFindings'
import { DisplayFocusedTopics } from './components/DisplayFocusedTopics'
import { DisplayPlatforms } from './components/DisplayPlatforms'
import { DisplayReportSettings } from './components/DisplayReportSettings'
import { DisplaySentimentAnalysis } from './components/DisplaySentimentAnalysis'
import { ReplacePdfPopUp } from './components/ReplacePdfPopUp'
import { ReportDetailsHeader } from './components/ReportDetailsHeader'
import { useHasCurrentScreenToken } from './Guards/SubjectGuards'

export const Subject = () => {
  useHasCurrentScreenToken('editSubject')
  const navigate = useNavigate()
  const reportInfo = useSelector(selectReportInfo).reportInfo
  const dispatch = useDispatch()

  const [getSubjectData, { data, isLoading: isLoadingGetSubjectData }] =
    useLazyGetSubjectQuery()

  useEffect(() => {
    if (reportInfo?.subjectId) {
      getSubjectData({ subjectId: reportInfo.subjectId }).then((response) => {
        if (response?.data?.info) {
          dispatch(setSubjectInfo(response.data.info))
        }
      })
    }
  }, [reportInfo, dispatch, getSubjectData, data])

  const onPressDowload = () => {
    window.open(data?.info.pdf, '_blank')
  }

  const onClickBack = () => {
    dispatch(setEditSubjectToken(false))
    navigate(AppRoutes.reports)
  }

  return (
    <WeScreen
      isLoading={isLoadingGetSubjectData}
      boxCustomGap={32}
      boxCustomPadding={16}
      useNextButton={false}
      onClickBack={onClickBack}
    >
      {data && reportInfo && (
        <ReportDetailsHeader
          fullName={data?.info?.personData?.name || 'Nameless'}
          data={data}
          profileImage={data?.info?.personData?.profilePicUrl || emptyAvatar}
          showActions
          primaryButtonTitle="Download PDF"
          primaryButtonOnClick={onPressDowload}
          secondaryButtonChildren={
            <ReplacePdfPopUp id={data?.info.id || ''} key={data?.info.id} />
          }
          id={data?.info.id}
          subjectId={reportInfo.subjectId}
          showCopyId
          reportInfo={reportInfo}
        />
      )}
      <Button onClick={() => navigate(AppRoutes.reportProfileFindings)}>
        Digital Footprint
      </Button>
      {data && <DisplayPlatforms socialMediaData={data?.info.socialMedia} />}
      {data && <DisplayFocusedTopics data={data?.info.input.topics} />}
      {data && <DisplayReportSettings reportInfo={reportInfo} />}

      {data && data.info.summary && 'executiveAim' in data.info.summary && (
        <DisplayCoreFindings summary={data.info.summary} />
      )}
      {data && data.info.summary && 'contentRadar' in data.info.summary && (
        <DisplaySentimentAnalysis
          summary={data.info.summary as ISentimentAnalysisResponse}
        />
      )}
    </WeScreen>
  )
}
