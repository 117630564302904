import { ESocialMedias, SITES } from '@/constants/socialMedias'
import { identifySocialMedia } from '@/utils/detect.social.media'

import { normalizeUrl } from './url'

/**
 * Sanitize Social Media Url:
 * Removes query params, trailing slashes, ensures https, excludes www, removes subdomain.
 *
 * @param url
 * @returns Sanitized Social Media Url
 */
export const sanitizeSocialMediaUrl = (url: string): string => {
  const socialMedia = identifySocialMedia(url)
  if (!socialMedia) return url

  const normalizedUrl = normalizeUrl(url)

  switch (socialMedia) {
    case ESocialMedias.LinkedIn:
      return sanitizeProfileLinkedIn(normalizedUrl)
    case ESocialMedias.Twitter:
      return sanitizeProfileTwitter(normalizedUrl)
    case ESocialMedias.Instagram:
      return sanitizeProfileInstagram(normalizedUrl)
    case ESocialMedias.Reddit:
      return sanitizeProfileReddit(normalizedUrl)
    case ESocialMedias.Pinterest:
      return sanitizeProfilePinterest(normalizedUrl)
    case ESocialMedias.Facebook:
      return sanitizeProfileFacebook(normalizedUrl)
    case ESocialMedias.TikTok:
      return sanitizeProfileTikTok(normalizedUrl)
    default:
      return url
  }
}

/**
 * Common Url Sanitization
 */
const removeQueryParams = (url: string): string => {
  return url.split('?')[0]
}

const removeTrailingSlashes = (url: string): string => {
  return url.replace(/\/+$/, '')
}

const ensureHttps = (url: string): string => {
  return url.startsWith('https://')
    ? url
    : `https://${url.replace(/^https?:\/\//, '')}`
}

const excludeWww = (url: string): string => {
  return url.includes('www.') ? url.replace('https://www.', 'https://') : url
}

const removeSubDomain = (url: string): string => {
  // Extract domain after https:// if present
  const urlWithoutProtocol = url.replace(/^https?:\/\//, '')

  for (const domain of SITES) {
    if (urlWithoutProtocol.includes(domain)) {
      const [, ...rest] = urlWithoutProtocol.split(domain)
      return `https://${domain}${rest.join(domain)}`
    }
  }

  return url
}

/**
 * LinkedIn Url Sanitization
 */
export const LINKEDIN_MOBILE_PREFIX = 'linkedin.com/mwlite/'
export const LINKEDIN_PROFILE_PREFIX = '/in'
export const LINKEDIN_URL_PREFIX = 'linkedin.com'
export const LINKEDIN_COMPANY_PREFIX = '/company/'
export const LINKEDIN_POST_PREFIX = '/posts/'
export const LINKEDIN_PUBLICATION_PREFIX = '/pub/'

export const sanitizeProfileLinkedIn = (url: string): string => {
  if (
    url.includes(LINKEDIN_POST_PREFIX) ||
    url.includes(LINKEDIN_PUBLICATION_PREFIX)
  ) {
    return url
  }

  let sanitizedUrl = removeQueryParams(url)
  sanitizedUrl = removeTrailingSlashes(sanitizedUrl)
  sanitizedUrl = ensureHttps(sanitizedUrl)
  sanitizedUrl = excludeWww(sanitizedUrl)
  sanitizedUrl = removeSubDomain(sanitizedUrl)

  // Convert mobile URLs to desktop format
  if (sanitizedUrl.includes(LINKEDIN_MOBILE_PREFIX)) {
    sanitizedUrl = sanitizedUrl.replace(
      LINKEDIN_MOBILE_PREFIX,
      LINKEDIN_URL_PREFIX,
    )
  }

  // Only add /in/ if it's a company URL
  if (!sanitizedUrl.includes(LINKEDIN_COMPANY_PREFIX)) {
    // Ensure /in/ is present for profile URLs
    if (!sanitizedUrl.includes(LINKEDIN_PROFILE_PREFIX)) {
      const parts = sanitizedUrl.split(LINKEDIN_URL_PREFIX)
      if (parts.length === 2 && !parts[1].startsWith(LINKEDIN_PROFILE_PREFIX)) {
        sanitizedUrl = `${parts[0]}${LINKEDIN_URL_PREFIX}${LINKEDIN_PROFILE_PREFIX}${parts[1]}`
      }
    }
  }

  return sanitizedUrl
}

/**
 * Twitter and X Url Sanitization
 */
export const TWITTER_MOBILE_PREFIX = 'mobile.twitter.com'
export const X_MOBILE_PREFIX = 'mobile.x.com'
export const X_URL_PREFIX = 'x.com'
export const TWITTER_URL_PREFIX = 'twitter.com'
export const X_URL_STATUS_PREFIX = '/status/'

export const sanitizeProfileTwitter = (url: string): string => {
  if (url.includes(X_URL_STATUS_PREFIX)) {
    return url
  }

  let sanitizedUrl = removeQueryParams(url)
  sanitizedUrl = removeTrailingSlashes(sanitizedUrl)
  sanitizedUrl = ensureHttps(sanitizedUrl)
  sanitizedUrl = excludeWww(sanitizedUrl)

  // Convert twitter.com to x.com
  if (sanitizedUrl.includes(TWITTER_URL_PREFIX)) {
    sanitizedUrl = sanitizedUrl.replace(TWITTER_URL_PREFIX, X_URL_PREFIX)
  }

  // Remove Old mobile prefixes
  if (sanitizedUrl.includes(TWITTER_MOBILE_PREFIX)) {
    sanitizedUrl = sanitizedUrl.replace(TWITTER_MOBILE_PREFIX, X_MOBILE_PREFIX)
  }

  // Remove mobile prefixes
  if (sanitizedUrl.includes(X_MOBILE_PREFIX)) {
    sanitizedUrl = sanitizedUrl.replace(X_MOBILE_PREFIX, X_URL_PREFIX)
  }

  return sanitizedUrl
}

/**
 * Instagram Url Sanitization
 */
export const INSTAGRAM_URL_PREFIX = 'instagram.com'
export const INSTAGRAM_POST_PREFIX = '/p/'

export const sanitizeProfileInstagram = (url: string): string => {
  if (url.includes(INSTAGRAM_POST_PREFIX)) {
    return url
  }

  let sanitizedUrl = removeQueryParams(url)
  sanitizedUrl = removeTrailingSlashes(sanitizedUrl)
  sanitizedUrl = ensureHttps(sanitizedUrl)
  sanitizedUrl = excludeWww(sanitizedUrl)

  return sanitizedUrl
}

/**
 * Reddit Url Sanitization
 */
export const REDDIT_OLD_PREFIX = 'old.reddit.com'
export const REDDIT_NP_PREFIX = 'np.reddit.com'
export const REDDIT_URL_PREFIX = 'reddit.com'
export const REDDIT_REDDIT_PREFIX = '/r/'

export const sanitizeProfileReddit = (url: string): string => {
  if (url.includes(REDDIT_REDDIT_PREFIX)) {
    return url
  }

  let sanitizedUrl = removeQueryParams(url)
  sanitizedUrl = removeTrailingSlashes(sanitizedUrl)
  sanitizedUrl = ensureHttps(sanitizedUrl)
  sanitizedUrl = excludeWww(sanitizedUrl)

  // Remove old.reddit.com and np.reddit.com prefixes
  if (sanitizedUrl.includes(REDDIT_OLD_PREFIX)) {
    sanitizedUrl = sanitizedUrl.replace(REDDIT_OLD_PREFIX, REDDIT_URL_PREFIX)
  }
  if (sanitizedUrl.includes(REDDIT_NP_PREFIX)) {
    sanitizedUrl = sanitizedUrl.replace(REDDIT_NP_PREFIX, REDDIT_URL_PREFIX)
  }

  // Ensure /user/ prefix for profile URLs
  if (!sanitizedUrl.includes('/user/')) {
    const parts = sanitizedUrl.split(REDDIT_URL_PREFIX)
    if (parts.length === 2 && !parts[1].startsWith('user/')) {
      sanitizedUrl = `${parts[0]}${REDDIT_URL_PREFIX}/user/${parts[1]}`
    }
  }

  return sanitizedUrl
}

/**
 * Pinterest Url Sanitization
 */
export const PINTEREST_PIN_PREFIX = 'pinterest.com/pin/'
export const PINTEREST_URL_PREFIX = 'pinterest.com'

export const sanitizeProfilePinterest = (url: string): string => {
  if (url.includes(PINTEREST_PIN_PREFIX)) {
    return url
  }

  let sanitizedUrl = removeQueryParams(url)
  sanitizedUrl = removeTrailingSlashes(sanitizedUrl)
  sanitizedUrl = ensureHttps(sanitizedUrl)
  sanitizedUrl = excludeWww(sanitizedUrl)
  sanitizedUrl = removeSubDomain(sanitizedUrl)

  return sanitizedUrl
}

/**
 * Facebook Url Sanitization
 *
 * Facebook has multiple type of URLs, we know that based of empiric testing,
 * we try to handle all of them and stadarize these URLs.
 * For that reason we'll need to documentate each one in order to dont forget any of them
 * in future updates.
 *
 * - Profile PHP ID :
 * https://www.facebook.com/profile.php?id=8644729
 *
 * - Profile /people/ ID :
 * https://www.facebook.com/people/Cesar-Ley/pfbid0B3JM7wctR86ve57iW4KNRqUBv1Vnkj2om4E3V8BNBzKG1HN1fkTLs6qWrgiwbxZYl/
 *
 * - Profile with hyphens :
 * https://www.facebook.com/andrew-michinard
 *
 * - Current dot Standar profile :
 * https://www.facebook.com/andrew.michinard
 *
 * @param url
 * @returns Sanitized Facebook Url
 */
export const FACEBOOK_URL_PREFIX = 'facebook.com'
export const FACEBOOK_MOBILE_PREFIX = 'm.facebook.com'
export const FACEBOOK_PHOTO_PREFIX = '/photos/'
export const FACEBOOK_PHOTO_ID_PREFIX = '/photo/'
export const FACEBOOK_POST_PREFIX = '/posts'
export const FACEBOOK_HASHTAG_PREFIX = '/hashtag/'

export const sanitizeProfileFacebook = (url: string): string => {
  // If the url includes any of the following prefixes,
  // is not a valid facebook profile url, so we return the original url
  if (
    url.includes(FACEBOOK_PHOTO_PREFIX) ||
    url.includes(FACEBOOK_POST_PREFIX) ||
    url.includes(FACEBOOK_HASHTAG_PREFIX) ||
    url.includes(FACEBOOK_PHOTO_ID_PREFIX)
  ) {
    return url
  }

  let sanitizedUrl = removeTrailingSlashes(url)
  sanitizedUrl = ensureHttps(sanitizedUrl)
  sanitizedUrl = excludeWww(sanitizedUrl)

  // Convert mobile Facebook URLs to desktop version
  if (sanitizedUrl.includes(FACEBOOK_MOBILE_PREFIX)) {
    sanitizedUrl = sanitizedUrl.replace(
      FACEBOOK_MOBILE_PREFIX,
      FACEBOOK_URL_PREFIX,
    )
  }

  /**
   * Handle profile.php?id= cases
   *
   * Example: https://www.facebook.com/profile.php?id=8644729
   */
  const profileIdMatch = sanitizedUrl.match(
    /facebook\.com\/profile\.php\?id=(\d+)/i,
  )
  if (profileIdMatch) {
    return `https://facebook.com/profile.php?id=${profileIdMatch[1]}`
  }

  /**
   * Handle /people/ cases
   *
   * Example: https://www.facebook.com/people/Cesar-Ley/pfbid0B3JM7wctR86ve57iW4KNRqUBv1Vnkj2om4E3V8BNBzKG1HN1fkTLs6qWrgiwbxZYl/
   */
  const peopleMatch = sanitizedUrl.match(
    /facebook\.com\/people\/([^\/]+)(?:\/([^\/\?]+))?/i,
  )
  if (peopleMatch) {
    const userName = peopleMatch[1]
    const peopleId = peopleMatch[2] ? `/${peopleMatch[2]}` : ''
    return `https://facebook.com/people/${userName}${peopleId}`
  }

  /**
   * Handle standard username cases
   *
   * Example: https://www.facebook.com/andrew-michinard
   * Example: https://www.facebook.com/andrew.michinard
   */

  // Extract and clean username, removing all parameters
  const usernameMatch = sanitizedUrl.match(/facebook\.com\/([^\/\?\&]+)/i)

  if (usernameMatch && usernameMatch[1]) {
    // Standardize username format: convert hyphens to dots and remove any trailing paths
    const username = usernameMatch[1].replace(/-/g, '.').split('/')[0]
    sanitizedUrl = `https://facebook.com/${username}`
  }

  return sanitizedUrl
}

/**
 * TikTok Url Sanitization
 */
export const TIKTOK_URL_PREFIX = 'tiktok.com'
export const TIKTOK_VIDEO_PREFIX = '/video/'
export const TIKTOK_MUSIC_PREFIX = '/music/'
export const TIKTOK_SHORT_URL_PREFIX = 'vm.tiktok.com'

export const sanitizeProfileTikTok = (url: string): string => {
  if (url.includes(TIKTOK_VIDEO_PREFIX) || url.includes(TIKTOK_MUSIC_PREFIX)) {
    return url
  }

  let sanitizedUrl = removeQueryParams(url)
  sanitizedUrl = removeTrailingSlashes(sanitizedUrl)
  sanitizedUrl = ensureHttps(sanitizedUrl)
  sanitizedUrl = excludeWww(sanitizedUrl)

  // Convert vm.tiktok.com short URLs to full tiktok.com URLs
  if (sanitizedUrl.includes(TIKTOK_SHORT_URL_PREFIX)) {
    sanitizedUrl = sanitizedUrl.replace(
      TIKTOK_SHORT_URL_PREFIX,
      TIKTOK_URL_PREFIX,
    )
  }

  // Remove any paths after the username
  const urlParts = sanitizedUrl.split(TIKTOK_URL_PREFIX + '/')
  if (urlParts.length === 2) {
    const username = urlParts[1].split('/')[0]
    sanitizedUrl = `https://www.${TIKTOK_URL_PREFIX}/${username}`
  }

  return sanitizedUrl
}
