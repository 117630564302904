import { api, RTKTags } from '@/store/api'

import Env from '../../../env/env'

import type {
  ProfilesRequest,
  ProfilesResponse,
  ScrapeProfilesRequest,
  UploadProfilesRequest,
} from './Scrape.types'

export const ScrapeApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getProfiles: builder.query<ProfilesResponse, ProfilesRequest>({
      query: (request) => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v1/admin/subjects/${request.subjectId}/profiles-findings`,
        method: 'GET',
      }),
      keepUnusedDataFor: 0,
      transformResponse: (response: any) => {
        return response.info
      },
      providesTags: [RTKTags.profileFindings],
    }),
    uploadProfiles: builder.mutation<void, UploadProfilesRequest>({
      query: (request) => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v1/admin/subjects/${request.subjectId}/profiles-findings`,
        method: 'PATCH',
        body: request.profiles,
      }),
      invalidatesTags: [RTKTags.profileFindings],
    }),

    scrapeProfiles: builder.mutation<void, ScrapeProfilesRequest>({
      query: (request) => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v1/admin/subjects/${request.subjectId}/scrape-profiles`,
        method: 'POST',
      }),
    }),
    updateDigitalFootprint: builder.mutation<
      void,
      { formData: FormData; subjectId: string }
    >({
      query: ({ formData, subjectId }) => ({
        url: `${Env.REACT_APP_BASE_API_URL}/api/v1/admin/subjects/${subjectId}/profiles-findings/digital-footprints`,
        method: 'PATCH',
        body: formData,
      }),
      invalidatesTags: [RTKTags.profileFindings],
    }),
  }),
})
export const {
  useGetProfilesQuery,
  useUploadProfilesMutation,
  useScrapeProfilesMutation,
  useUpdateDigitalFootprintMutation,
} = ScrapeApi
