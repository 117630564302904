import React from 'react'
import { useSelector } from 'react-redux'

import { WeScreen } from '@/components/WeScreen/WeScreen'
import {
  useGetEnterprisesQuery,
  useGetUsersByEnterpriseQuery,
} from '@/store/api/Enterprises/Enterprises.query'
import { selectEnteprise } from '@/store/api/Enterprises/Enterprises.selector'

import { CompanyUsersHeader } from './Components/CompanyUsersHeader'
import { CompanyUsersTable } from './Components/CompanyUsersTable'

export const CompanyUsers = () => {
  const enterpriseId = useSelector(selectEnteprise).id
  const {
    data: enterpriseData,
    isLoading: isLoadingEnterpriseQuery,
    isError: isErrorEnterpriseQuery,
  } = useGetEnterprisesQuery({})

  const enterprise = enterpriseData?.info.list.find(
    (enterprise) => enterprise.id === enterpriseId,
  )

  const {
    data: usersEnterpriseData,
    isLoading: isLoadingUsersEnterpriseQuery,
    isError: isErrorUsersEnterprise,
  } = useGetUsersByEnterpriseQuery({
    id: enterpriseId,
  })

  const isLoading = isLoadingUsersEnterpriseQuery || isLoadingEnterpriseQuery
  const isError = isErrorUsersEnterprise || isErrorEnterpriseQuery

  return (
    <WeScreen
      useNextButton={false}
      isLoading={isLoading}
      isError={isError}
      boxCustomPadding={16}
    >
      {enterprise && (
        <CompanyUsersHeader
          companyName={enterprise.name}
          logo={enterprise.logo}
          enterpriseData={enterprise}
        />
      )}

      {usersEnterpriseData && (
        <CompanyUsersTable
          usersData={usersEnterpriseData?.info}
          enterpriseId={enterpriseId}
        />
      )}
    </WeScreen>
  )
}
