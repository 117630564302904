import React from 'react'

import {
  Sheet,
  SheetTrigger,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetDescription,
} from '@/components/ui/sheet'
import type { ReportItem } from '@/store/api/Admin/Admin.types'

import { ReportSettingsCard } from './ReportSettingsCard'

export const ReportSettingSheet = ({ data }: { data: ReportItem }) => {
  const pdfPreferences = [
    { title: 'Core Findings', value: data.pdfCoreFindings },
    {
      title: 'Social Media Accounts',
      value: data.pdfSocialMediaAccounts,
    },
    {
      title: 'Sentiment Analysis',
      value: data.pdfSentimentAnalysis,
    },
    {
      title: 'Flagged Behaviors',
      value: data.pdfFlaggedBehaviors,
    },
    { title: 'Positive Posts', value: data.pdfPositivePosts },
    { title: 'Negative Posts', value: data.pdfNegativePosts },
    { title: 'Neutral Posts', value: data.pdfNeutralPosts },
    { title: 'Web Search Posts', value: data.pdfWebSearchPosts },
    { title: 'Focused Topics', value: data.pdfFocusedTopics },
    {
      title: 'Personality Insights & Skills',
      value: data.pdfPersonalityAndSkills,
    },
    { title: 'Career & Education', value: data.pdfEducationAndCareer },
  ]

  return (
    <Sheet>
      <SheetTrigger className="w-full">
        <p className="relative flex w-full cursor-default select-none items-center rounded-sm py-1.5 pl-2 pr-8 outline-none focus:bg-primaryColor5 focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50 h-[35px] p-2 text-neutralColor60 text-sm font-medium tracking-tight hover:bg-primaryColor5">
          PDF Preferences
        </p>
      </SheetTrigger>
      <SheetContent className="flex flex-col gap-5">
        <SheetHeader className="flex flex-col gap-3">
          <SheetTitle className="text-xl font-medium text-neutralColor">
            Report Settings
          </SheetTitle>
          <SheetDescription className="text-base font-normal text-neutralColor60">
            The settings below outline the components and analysis methods that
            were used to generate this report.
          </SheetDescription>
        </SheetHeader>
        <div className="flex flex-col gap-5 w-full">
          <div className="w-full h-[22px] flex justify-between items-center">
            <p className="text-base font-medium text-neutralColor80">
              Language
            </p>
            <p className="text-sm font-medium tracking-tight text-neutralColor">
              {data.pdfLanguage === 'en' ? 'English' : 'Spanish'}
            </p>
          </div>
          {pdfPreferences.map((preference, index) => (
            <ReportSettingsCard
              key={index}
              title={preference.title}
              value={preference.value}
            />
          ))}
        </div>
      </SheetContent>
    </Sheet>
  )
}
